import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getNote, createOrUpdateNote } from '../../redux/_actions/noteAction';
import { getContactsByType } from '../../redux/_actions/contactAction';
import { setAlert } from '../../redux/_actions/alertAction';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import { useForm } from 'react-hook-form';
import { useParams } from "react-router";
import moment from 'moment';

const Note = ({ props }) => {

  const { id } = useParams();
  const { register, handleSubmit, reset, errors } = useForm();
  let dbNote = useSelector(state => state.note.note);
  let contacts = useSelector(state => state.contact.contactsByType);
  const dispatch = useDispatch();

  if (window.location.pathname === '/note/create') {
    dbNote = null;
  }

  const [note, setNote] = useState({
    _id: '',
    owner: '',
    noteDate: '',
    description: '',
    contactId: '',
    contactName: ''
  });

  const onChange = (e) =>
    setNote({ ...note, [e.target.name]: e.target.value }
  );


  const onSubmit = (data) => {
    if (data.noteDate === '' || data.description === ''){
      dispatch(setAlert('Notitiedatum en omschrijving zijn verplicht, voer deze allemaal in.', 'danger'));
    } else {
      if (window.location.pathname !== '/note/create') {
        data._id = dbNote._id;
      }
      if (data.contactId) {
        const contact = contacts.find(c => c._id === data.contactId);
        if (contact){
          data.contactName = contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName;
        }
      }
      dispatch(createOrUpdateNote(data));
    } 
  }

  useEffect(() => {
    dispatch(getContactsByType('Klant'));
    
    if (window.location.pathname !== '/note/create') {
      dispatch(getNote(id)).then((inv) => {
        if (inv) {
          inv.noteDate = moment(new Date(inv.noteDate)).format('yyyy-MM-DD');
          reset(inv);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
      <div>
        <SideBar {...props} />
        <div className="body-content content-wrapper">

          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className="icon-list short" title="Notities"> </h2>
            <div className="row">

              <div className="col-md-12">
                <div className="box box-primary">
                  <div className="box-header with-border">
                    <h3 className="box-title">Notitie</h3>
                  </div>

                    <div className="box-body">

                    {dbNote && dbNote.noteNumber ? (
                      <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">Nummer</label>
                        <div className="col-8 contact-number">
                          {dbNote ? dbNote.noteNumber : ''}
                        </div>
                      </div>) : ''}

                      <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">Contact</label>
                        <div className="col-8">
                          <select name="contactId"
                            ref={register({ required: true })}
                            placeholder="Selecteer contact"
                            defaultValue={dbNote ? dbNote.contactId : ''}
                            style={{ border: errors.contactId ? '2px solid #D0021B' : '' }}
                            onChange={onChange} >
                              <option value="" >Selecteer een contact...</option>
                              {contacts ? contacts.map(contact => (
                                <option
                                  key={contact._id}
                                  value={contact._id}
                                >
                                  {contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName}
                                </option>
                              )) : ''}
                          </select>
                          {errors.contactId && <span className='error'>Kies een contact</span>}
                        </div>
                      </div>
                      <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">Notitiedatum</label>
                        <div className="col-8">
                          <input
                            type="date"
                            name="noteDate"
                            className="form-control"
                            placeholder="Notitiedatum"
                            defaultValue={dbNote ? moment(new Date(dbNote.noteDate)).format('yyyy-MM-DD') : new Date().toISOString().substr(0,10)}
                            onChange={onChange}
                            ref={register({ required: true })}
                            style={{ border: errors.noteDate ? '2px solid #D0021B' : '' }}
                            />
                            {errors.noteDate && <span className='error'>Voer een notitiedatum in</span>}
                        </div>
                      </div>
                      <div className="form-group required row">
                          <label className="col-form-label col-4 form-label">Omschrijving</label>
                          <div className="col-8">
                            <textarea
                              name="description"
                              className="form-control"
                              placeholder="Omschrijving"
                              defaultValue={dbNote ? dbNote.description : ''}
                              onChange={onChange}
                              ref={register({ required: true })}
                              style={{ border: errors.description ? '2px solid #D0021B' : '' }}
                              />
                              {errors.description && <span className='error'>Voer een omschrijving in</span>}
                          </div>
                        </div>
                    </div>
                    <div className="box-footer">
                      <button type="submit" className="btn btn-primary" >Opslaan</button>
                    </div>
                </div>
              </div>

            </div>
          </form>

          <Footer />
        </div>

    </div>);
}
export default Note
