import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getContact } from '../../redux/_actions/contactAction';
import { setAlert } from '../../redux/_actions/alertAction';
import { createOrUpdateContact } from '../../redux/_actions/contactAction';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useParams } from "react-router";

const ContactDetails = ({ props }) => {
  const { id } = useParams();
  const { register, handleSubmit, reset, errors, getValues } = useForm();
  let dbContact = useSelector(state => state.contact.contact);
  const loading = useSelector(state => state.contact.loading);
  const dispatch = useDispatch();

  if (window.location.pathname === '/contact/create') {
    dbContact = null;
  }

  const [contact, setContact] = useState({
    _id: '',
    firstName: '',
    lastName: '',
    companyName: '',
    emailAddress: '',
    typeOfContact: '',
    contactNumber: '',
    initials: '',
    gender: '',
    phoneNumber: '',
    mobilePhoneNumber: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    country: '',
    bankIBAN: '',
    bankPersonName: '',
    visitingStreet: '',
    visitingHouseNumber: '',
    visitingPostalCode: '',
    visitingCity: '',
    visitingCountry: '',
    typeName: '',
    channel: '',
    history: '',
  });
  const onChange = (e) =>
    setContact({ ...contact, [e.target.name]: e.target.value }
    );

  const onSubmit = data => {


    if ((data.lastName === '' && data.typeOfContact === 'Particulier') ||
      (data.firstName === '' && data.typeOfContact === 'Particulier') ||
      (data.companyName === '' && data.typeOfContact === 'Bedrijf') ||
      data.emailAddress === '' ||
      data.typeOfContact === '' ||
      data.country === '')
      dispatch(setAlert('Achternaam en voornaam (in geval van "Particulier"), Bedrijfsnaam (in geval van "Bedrijf") en email, type en land zijn verplicht, voer deze allemaal in.', 'danger'));
    else {

      if (window.location.pathname !== '/contact/create') {
        data._id = dbContact._id;
      }
      dispatch(createOrUpdateContact(data));
    }
  }

  const isLastNameValid = async (typeOfContact, value) => {
    if (typeOfContact === 'Bedrijf') {
      return true;
    } else if (typeOfContact === 'Particulier') {
      if (value) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isCompanyNameValid = async (typeOfContact, value) => {
    if (typeOfContact === 'Particulier') {
      return true;
    } else if (typeOfContact === 'Bedrijf') {
      if (value) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (window.location.pathname !== '/contact/create') {
      dispatch(getContact(id)).then((con) => {
        if (con) {
          reset(con);
          setContact({ ...contact, 'typeName': con.typeName });
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (<div>Loading...</div>) : (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Contactgegevens</h3>
              </div>

              <div className="box-body">

                {dbContact && dbContact.contactNumber ? (
                  <div className="form-group required row">
                    <label className="col-form-label col-4 form-label">Nummer</label>
                    <div className="col-8 contact-number">
                      {dbContact ? dbContact.contactNumber : ''}
                    </div>
                  </div>) : ''}

                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Klant/Leverancier</label>
                  <div className="col-8">
                    <select name="typeOfContact"
                      ref={register({ required: true })}
                      placeholder="Selecteer type contact"
                      defaultValue={dbContact ? dbContact.typeOfContact : ''}
                      onChange={onChange}
                      style={{ border: errors.typeOfContact ? '2px solid #D0021B' : '' }}>
                      <option value="Klant">Klant</option>
                      <option value="Leverancier">Leverancier</option>
                    </select>
                    {errors.typeOfContact && <span className='error'>Kies voor klant of leverancier</span>}
                  </div>
                </div>

                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Particulier/Bedrijf</label>
                  <div className="col-8">
                    <select name="typeName"
                      ref={register({ required: true })}
                      placeholder="Kies het type"
                      defaultValue={dbContact ? dbContact.typeName : ''}
                      onChange={onChange}
                      style={{ border: errors.typeName ? '2px solid #D0021B' : '' }}
                    >
                      <option value="Particulier">Particulier</option>
                      <option value="Bedrijf">Bedrijf</option>
                    </select>
                    {errors.typeName && <span className='error'>Kies voor particulier of bedrijf</span>}
                  </div>
                </div>

                {contact && contact.typeName !== 'Particulier' ? (
                  <div className="form-group required row" >
                    <label className="col-form-label col-4 form-label">Bedrijfsnaam</label>
                    <div className="col-8">
                      <input
                        type="text"
                        name="companyName"
                        className="form-control"
                        placeholder="Bedrijfsnaam"
                        defaultValue={dbContact ? dbContact.companyName : ''}
                        onChange={onChange}
                        ref={register({
                          validate: val => isCompanyNameValid(getValues("typeName"), val)
                        })}
                        style={{ border: errors.companyName && errors.companyName.type === "validate" ? '2px solid #D0021B' : '' }}
                      />
                      {errors.companyName && errors.companyName.type === "validate" && (<span className='error'>Voer een bedrijfsnaam in</span>)}
                    </div>
                  </div>) : ''}

                {contact && contact.typeName === 'Bedrijf' ? (
                  <div className="form-group row box-header-container">
                    <label className="col-form-label box-header-label"><strong>Contactpersoon</strong></label>
                  </div>
                ) : ''}
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Achternaam</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="Achternaam"
                      defaultValue={dbContact ? dbContact.lastName : ''}
                      onChange={onChange}
                      ref={register({
                        validate: val => isLastNameValid(getValues("typeName"), val)
                      })}
                      style={{ border: errors.lastName && errors.lastName.type === "validate" ? '2px solid #D0021B' : '' }}
                    />
                    {errors.lastName && errors.lastName.type === "validate" && (<span className='error'>Voer een achternaam in</span>)}
                  </div>
                </div>

                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Voornaam</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="Voornaam"
                      defaultValue={dbContact ? dbContact.firstName : ''}
                      onChange={onChange}
                      ref={register({
                        validate: val => isLastNameValid(getValues("typeName"), val)
                      })}
                      style={{ border: errors.firstName && errors.firstName.type === "validate" ? '2px solid #D0021B' : '' }}
                    />
                    {errors.firstName && errors.firstName.type === "validate" && <span className='error'>Voer een voornaam in</span>}
                  </div>
                </div>

                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Initialen</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="initials"
                      className="form-control"
                      placeholder="Voorletters"
                      defaultValue={dbContact ? dbContact.initials : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>

                {contact && contact.typeName === 'Bedrijf' ? (
                  <div className="row box-header-end">
                  </div>
                ) : ''}


                {/* <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Geslacht</label>
                  <div className="col-8">
                    <select name="gender"
                      ref={register({ required: true })}
                      placeholder="Selecteer geslacht"
                      defaultValue={dbContact ? dbContact.gender : ''}
                      onChange={onChange}
                      style={{ border: errors.gender ? '2px solid #D0021B' : '' }}
                    >
                      <option value="Man">Man</option>
                      <option value="Vrouw">Vrouw</option>
                    </select>
                    {errors.gender && <span className='error'>Voer een voornaam in</span>}
                  </div>
                </div> */}
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Emailadres</label>
                  <div className="col-8">
                    <input
                      type="email"
                      name="emailAddress"
                      className="form-control"
                      placeholder="Emailadres"
                      defaultValue={dbContact ? dbContact.emailAddress : ''}
                      onChange={onChange}
                      ref={register({
                        required: true,
                        /*eslint no-useless-escape: */
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                      style={{ border: errors.emailAddress ? '2px solid #D0021B' : '' }}
                    />
                    {errors.emailAddress && <span className='error'>Voer een geldig emailadres in</span>}
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Telefoonnummer</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="phoneNumber"
                      className="form-control"
                      placeholder="Telefoonnummer"
                      defaultValue={dbContact ? dbContact.phoneNumber : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Mobiele nummer</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="mobilePhoneNumber"
                      className="form-control"
                      placeholder="Mobiele nummer"
                      defaultValue={dbContact ? dbContact.mobilePhoneNumber : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
              </div>

              <div className="box-footer">

                <button type="submit" className="btn btn-primary" >Opslaan</button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Adresgegevens</h3>
              </div>

              <div className="box-body">
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Straat</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="street"
                      className="form-control"
                      placeholder="Straat"
                      defaultValue={dbContact ? dbContact.street : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Huisnummer</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="houseNumber"
                      className="form-control"
                      placeholder="Huisnummer (en toevoeging)"
                      defaultValue={dbContact ? dbContact.houseNumber : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Postcode</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="postalCode"
                      className="form-control"
                      placeholder="Postcode"
                      defaultValue={dbContact ? dbContact.postalCode : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Plaats</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="Woonplaats"
                      defaultValue={dbContact ? dbContact.city : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Selecteer land</label>
                  <div className="col-8">
                    <select name="country"
                      ref={register({ required: true })}
                      placeholder="Selecteer land"
                      defaultValue={dbContact ? dbContact.country : ''}
                      onChange={onChange}
                      style={{ border: errors.country ? '2px solid #D0021B' : '' }}
                    >
                      <option value="Nederland">Nederland</option>
                      <option value="België">België</option>
                      <option value="Duitsland">Duitsland</option>
                      <option value="Spanje">Spanje</option>
                      <option value="Engeland">Engeland</option>
                    </select>
                    {errors.country && <span className='error'>Kies een land</span>}
                  </div>
                </div>
              </div>

              <div className="box-footer">
                <button type="submit" className="btn btn-primary" >Opslaan</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Bankgegevens</h3>
              </div>

              <div className="box-body">
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">IBAN</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="bankIBAN"
                      className="form-control"
                      placeholder="IBAN"
                      defaultValue={dbContact ? dbContact.bankIBAN : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Ten name van</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="bankPersonName"
                      className="form-control"
                      placeholder="Ten name van"
                      defaultValue={dbContact ? dbContact.bankPersonName : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
              </div>

              <div className="box-footer">
                <button type="submit" className="btn btn-primary" >Opslaan</button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Bezoekadres</h3>
              </div>

              <div className="box-body">
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Straat</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="visitingStreet"
                      className="form-control"
                      placeholder="Straat"
                      defaultValue={dbContact ? dbContact.visitingStreet : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Huisnummer</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="visitingHouseNumber"
                      className="form-control"
                      placeholder="Huisnummer (en toevoeging)"
                      defaultValue={dbContact ? dbContact.visitingHouseNumber : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Postcode</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="visitingPostalCode"
                      className="form-control"
                      placeholder="Postcode"
                      defaultValue={dbContact ? dbContact.visitingPostalCode : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Woonplaats</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="visitingCity"
                      className="form-control"
                      placeholder="Woonplaats"
                      defaultValue={dbContact ? dbContact.visitingCity : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Selecteer land</label>
                  <div className="col-8">
                    <select name="visitingCountry"
                      ref={register(false)}
                      placeholder="Selecteer land"
                      defaultValue={dbContact ? dbContact.visitingCountry : ''}
                      onChange={onChange} >
                      <option value="Nederland">Nederland</option>
                      <option value="België">België</option>
                      <option value="Duitsland">Duitsland</option>
                      <option value="Spanje">Spanje</option>
                      <option value="Engeland">Engeland</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="box-footer">
                <button type="submit" className="btn btn-primary" >Opslaan</button>
              </div>
            </div>
          </div>

          <div id="extra-info-div" className="col-md-6">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Extra info</h3>
              </div>

              <div className="box-body">
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Kanaal</label>
                  <div className="col-8">
                    <input
                      type="text"
                      name="channel"
                      className="form-control"
                      placeholder="Kanaal"
                      defaultValue={dbContact ? dbContact.channel : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
                <div className="form-group required row">
                  <label className="col-form-label col-4 form-label">Geschiedenis</label>
                  <div className="col-8">
                    <textarea
                      type="text"
                      name="history"
                      className="form-control"
                      placeholder="Geschiedenis"
                      defaultValue={dbContact ? dbContact.history : ''}
                      onChange={onChange}
                      ref={register(false)}
                    />
                  </div>
                </div>
              </div>

              <div className="box-footer">
                <button type="submit" className="btn btn-primary">Opslaan</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
export default withRouter(ContactDetails); 