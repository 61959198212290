
export const EXPENSE_LOADED = 'EXPENSE_LOADED';
export const EXPENSE_LOADED_FAIL = 'EXPENSE_LOADED_FAIL';

export const EXPENSES_LOADED = 'EXPENSES_LOADED';
export const EXPENSES_LOADED_FAIL = 'EXPENSES_LOADED_FAIL';

export const EXPENSE_UPDATED_OR_CREATED_SUCCESS = 'EXPENSE_UPDATED_OR_CREATED_SUCCESS';
export const EXPENSE_UPDATED_OR_CREATED_FAIL = 'EXPENSE_UPDATED_OR_CREATED_FAIL';
export const EXPENSE_DELETED = 'EXPENSE_DELETED';
export const EXPENSE_DELETED_FAIL = 'EXPENSE_DELETED_FAIL';


 