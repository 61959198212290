import {  EXPENSES_LOADED, 
          EXPENSES_LOADED_FAIL, 
          EXPENSE_LOADED, 
          EXPENSE_LOADED_FAIL,
          EXPENSE_UPDATED_OR_CREATED_SUCCESS, 
          EXPENSE_UPDATED_OR_CREATED_FAIL,
          EXPENSE_DELETED,
          EXPENSE_DELETED_FAIL} from '../expenseTypes'

const initState = {
  expense: null,
  error: null,
  loading: true,
  offset: 0,
  totalDocs: 0,
  totalPages: 0,
  limit: 0
}

const expenseReducer = (state = initState, action) => {
  switch(action.type){
      case EXPENSE_LOADED:
        return {
          ...state,
          loading: false,
          expense: action.payload
         }
      case EXPENSES_LOADED:
        const { totalDocs, limit, offset, totalPages } = action.payload;
        return {
          ...state,
          loading: false,
          expenses: action.payload.docs,
          limit: limit,
          offset:  offset,
          totalDocs: totalDocs ,
          totalPages: totalPages 
        }
      case EXPENSE_LOADED_FAIL:
        return state;
      case EXPENSES_LOADED_FAIL:
        return state;
      case EXPENSE_UPDATED_OR_CREATED_FAIL:
        return state;
      case EXPENSE_DELETED:
        const newExpenselist = state.expenses.filter((item) => item._id !== action.payload._id);
        return {
          ...state,
          loading: false,
          expenses: newExpenselist,
        //  expense: action.payload
         }
      case EXPENSE_DELETED_FAIL:
        return state;
      case EXPENSE_UPDATED_OR_CREATED_SUCCESS:
        return state;
      default:
        return state;
  }     
}

export default expenseReducer;