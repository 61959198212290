/* eslint-disable no-underscore-dangle */
import { Row, Col, Form, Button } from 'react-bootstrap';
import InputHint from '../../components/InputHint/InputHint';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getProfile, updateProfile } from '../../redux/_actions/authAction';
import { setAlert } from '../../redux/_actions/alertAction';
import { useForm } from 'react-hook-form';

const Profile = ({ history, props }) => {

  const { register, handleSubmit } = useForm();
  const auth = useSelector(state => state.auth);
  const dbUser = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    id:'',
    name: '',
    companyName: '',
    email: '',
    password: '',
    currentPassword: '',
    newPassword: ''
  });

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = data => {
    if (data.name === '' || data.email === '' )
      dispatch(setAlert('Naam en email zijn verplicht, voer deze in.', 'danger'));
    else {
      dispatch(updateProfile(data)); 
    }
  }

  useEffect(() => {
    dispatch(getProfile());
    // eslint-disable-next-line
  }, []);

  const renderPasswordUser = (auth, dbUser) => {
   var loading = auth.loading;

    return !loading ? (<div>
        <Form.Group>
          <Form.Label>Naam</Form.Label>
          <input type="hidden" name="_id" defaultValue={dbUser._id}  ref={register(false)}/>
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Naam"
            defaultValue={dbUser.name}
            onChange={onChange} 
            ref={register(true)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            defaultValue={dbUser.email} 
            onChange={onChange} 
            ref={register(true)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Bedrijfsnaam</Form.Label>
          <input
            type="text"
            name="companyName"
            className="form-control"
            placeholder="Bedrijfsnaam"
            defaultValue={dbUser.companyName} 
            onChange={onChange} 
            ref={register(false)}
            autoComplete="Companyname"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Huidig Wachtwoord</Form.Label>
          <input
            type="password"
            name="currentPassword"
            autoComplete="current-password"
            className="form-control"
            placeholder="Huidig wachtwoord"
            minLength="6"
            onChange={onChange}
            ref={register(false)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Nieuw Wachtwoord</Form.Label>
          <input
            type="password"
            name="newPassword"
            autoComplete="new-password"
            className="form-control"
            placeholder="Nieuw wachtwoord"
            minLength="6"
            onChange={onChange}
            ref={register(false)}
          />
          <InputHint>Gebruik minstens zes karakters.</InputHint>
        </Form.Group>

    </div>) : <div />;
  }

  return (<div>
      <SideBar {...props} />
      <div className="body-content content-wrapper">
      <h2 className="icon-user short" title="Contacten/klanten"> </h2>
      <Row>
        <Col md={12}>
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">Gebruiker</h3>
            </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                 <div className="box-body">

                   {renderPasswordUser(auth, dbUser)}

                  <div className="box-footer">
                    <Button type="submit">Profiel Opslaan</Button>
                  </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>

  </div>);
}
export default Profile