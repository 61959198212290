import { CONTACT_LOADED, 
         CONTACT_LOADED_FAIL, 
         CONTACTS_LOADED, 
         CONTACTS_LOADED_FAIL, 
         CONTACT_UPDATED_SUCCESS, 
         CONTACT_UPDATED_FAIL, 
         CONTACTS_BY_TYPE_LOADED, 
         CONTACTS_BY_TYPE_LOADED_FAIL,
         CONTACT_DELETED,
         CONTACT_DELETED_FAIL
        } from '../contactTypes'
import axios from 'axios';
import { setAlert } from './alertAction'


export const getContacts = (query) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/contacts${query}`, config);
            dispatch({type: CONTACTS_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            if(err && err.response && err.response.data){
              dispatch({type: CONTACTS_LOADED_FAIL, payload: err.response.data.message});
            } else {
              console.log(err);
              dispatch({type: CONTACTS_LOADED_FAIL, payload: err});
            }
            dispatch(setAlert('Er is iets misgegaan bij het laden van de contacten, probeer het nogmaals.', 'danger'));
          }     
      }
}

export const getContactsByType = (typeName) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/contacts/type/${typeName}`, config);
            dispatch({type: CONTACTS_BY_TYPE_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            if(err && err.response && err.response.data){
              dispatch({type: CONTACTS_BY_TYPE_LOADED_FAIL, payload: err.response.data.message});
            } else {
              console.log(err);
              dispatch({type: CONTACTS_BY_TYPE_LOADED_FAIL, payload: err});
            }
            dispatch(setAlert('Er is iets misgegaan bij het laden van contacten, probeer het nogmaals.', 'danger'));
          }     
      }
}

export const getContact = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/contact/${id}`, config);
            dispatch({type: CONTACT_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            if(err && err.response && err.response.data){
              dispatch({type: CONTACT_LOADED_FAIL, payload: err.response.data.message});
            } else {
              console.log(err);
              dispatch({type: CONTACT_LOADED_FAIL, payload: err});
            }
            dispatch(setAlert('Er is iets misgegaan bij het laden van het contact, probeer het nogmaals.', 'danger'));
          }     
      }
}

export const createOrUpdateContact = (contact) => {
  return async dispatch => {
    try {
          const config = {header: {'Content-Type': 'application/json'}}  
          const res = await axios.post(`/api/contact`, contact, config);
          dispatch({type: CONTACT_UPDATED_SUCCESS, payload: res.data.data});
          dispatch(setAlert('Het contact is succesvol opgeslagen.', 'success'));
          return res.data.data;
        } catch (err) {
          if(err && err.response && err.response.data){
            dispatch({type: CONTACT_UPDATED_FAIL, payload: err.response.data.message});
          } else {
            console.log(err);
            dispatch({type: CONTACT_UPDATED_FAIL, payload: err});
          }
          dispatch(setAlert('Er is iets misgegaan bij het opslaan van het profiel.', 'danger'));
        }     
  }
}

export const deleteContact = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.delete(`/api/contact/${id}`, config);
            dispatch({type: CONTACT_DELETED, payload: res.data.data});
          } catch (err) {
            if(err && err.response && err.response.data){
              dispatch({type: CONTACT_DELETED_FAIL, payload: err.response.data.message});
            } else {
              console.log(err);
              dispatch({type: CONTACT_DELETED_FAIL, payload: err});
            }
            dispatch(setAlert('Er is iets misgegaan bij het laden van het contact, probeer het nogmaals.', 'danger'));
          }     
      }
  }

