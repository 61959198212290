import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getInvoice, createOrUpdateInvoice } from '../../redux/_actions/invoiceAction';
import { getContactsByType } from '../../redux/_actions/contactAction';
import { getSettings } from '../../redux/_actions/settingsAction';
import { setAlert } from '../../redux/_actions/alertAction';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import { useForm } from 'react-hook-form';
import { useParams } from "react-router";
import LineItems from '../../components/partials/LineItems';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { saveAs } from 'file-saver';
const { v4: uuidv4 } = require('uuid');

const Invoice = ({ props }) => {

  const { id, contactId } = useParams();
  const history = useHistory();
  const { register, handleSubmit, reset, errors } = useForm();
  let dbInvoice = useSelector(state => state.invoice.invoice);
  let contacts = useSelector(state => state.contact.contactsByType);
  const dispatch = useDispatch();
  
  
  if (window.location.pathname === '/invoice/create') {
    dbInvoice = null;
  }

  let localState = {
    _id: '',
    contactId: '',
    invoiceNumber: '',
    invoiceDate: '',
    payDate: '',
    tax: 0.00,
    priceWOTaxes: 0,
    price:0,
    state: '',
    invoiceLines: [
      {
        id: uuidv4(),    // react-beautiful-dnd unique key
        description: '',
        numberOfItems: 0,
        priceWOTaxes: 0.00,
        totalLinePrice: 0.00
      },
    ]
  };

  const [taxRate, setTaxRate] = useState(0);
  const [localPriceWOTaxes, setPriceWOTaxes] = useState({ priceWOTaxes: 0.00} );
  const [localTaxTotal, setTaxTotal] = useState({ tax: 0.00} );
  const [localTotalPrice, setTotalPrice] = useState({ price: 0.00} );

  const [invoice, setInvoice] = useState({
    _id: '',
    contactId: '',
    invoiceNumber: '',
    invoiceDate: '',
    payDate: '',
    tax: 0.00,
    priceWOTaxes: 0,
    price:0,
    state: '',
    invoiceLines: [
      {
        _id:'',     
        description: '',
        numberOfItems: 0,
        priceWOTaxes: 0.00,
        totalLinePrice: 0.00
      },]
  });



  const onChange = (e) =>
    setInvoice({ ...invoice, [e.target.name]: e.target.value }
  );

  const onSubmit = (data)  => {

   if(data.invoiceDate === '' ||
      data.tax === '' ){
      dispatch(setAlert('Factuurdatum en btw zijn verplicht, voer deze allemaal in.', 'danger'));
    } else {
      if (localPriceWOTaxes.priceWOTaxes > 0){
        data.priceWOTaxes = localPriceWOTaxes.priceWOTaxes;
      }
      if (localTaxTotal.tax > 0){
        data.tax = localTaxTotal.tax;
      }
      if (localTotalPrice.price > 0){
        data.price = localTotalPrice.price;
      }
      if (data.contactId) {
        const contact = contacts.find(c => c._id === data.contactId);
        if (contact){
          data.contactName = contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName;
        }
      }
      if (invoice.invoiceLines){
        data.invoiceLines = invoice.invoiceLines;
      }

      if (window.location.pathname !== '/invoice/create') {
        data._id = dbInvoice._id;
      }
      dispatch(createOrUpdateInvoice(data));
    }
  }
    
  const handleLineItemChange = (elementIndex) => (e) => {
    let lineItems = invoice.invoiceLines.map((item, i) => {
      if (elementIndex !== i) return item
      return {...item, [e.target.name]: e.target.value};
    });

    setInvoice({ ...invoice, invoiceLines: lineItems });
    updateTotals(lineItems);
  }

  const updateTotals = (lineItems) => {
    const lineItemTotal = lineItems.reduce((prev, cur) => (prev + (cur.numberOfItems * cur.priceWOTaxes)), 0);
    if (localPriceWOTaxes.priceWOTaxes !== lineItemTotal){
      let taxTotal;
      if (taxRate > 0) {
        taxTotal = lineItemTotal * (taxRate / 100);
      } else {
        taxTotal = 0 ;
      }
      const grandTotal = lineItemTotal + taxTotal;
      setPriceWOTaxes({ ...localPriceWOTaxes, priceWOTaxes: parseFloat(lineItemTotal)});
      setTaxTotal({ ...localTaxTotal, tax: parseFloat(taxTotal)});
      setTotalPrice({ ...localTotalPrice, price: parseFloat(grandTotal)}); 
    }
  }

  const handleAddLineItem = (e) => {
    e.preventDefault();
    setInvoice({ ...invoice, invoiceLines: invoice.invoiceLines.concat(
      [{ id: uuidv4(), description: '', numberOfItems: 0, priceWOTaxes: 0.00 }]
    ) });
  }

  const handleRemoveLineItem = (elementIndex) => (e) => {
    e.preventDefault();
    setInvoice({ ...invoice, invoiceLines: invoice.invoiceLines.filter((item, i) => {
      return elementIndex !== i
    }) });
  }

  const handleReorderLineItems = (newLineItems) => {
    setInvoice({ ...invoice, invoiceLines: newLineItems });
  }

  const handleFocusSelect = (e) => {
    e.target.select()
  }


  const formatCurrency = (amount) => {
    return (new Intl.NumberFormat('NL-nl', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount))
  }
  
  useEffect(() => {
    dispatch(getContactsByType('Klant'));

    dispatch(getSettings()).then((settings) => {
      if (settings) {
        let percentage = parseFloat(settings.taxPercentage);
        if (percentage > 0){
          percentage = percentage / 100;
        } 
        setTaxRate(percentage);
      }
    });
  
    if (window.location.pathname.startsWith('/invoice/create')) {
      setInvoice({ ...invoice, invoiceLines: localState.invoiceLines });
      if (contactId){
        const dataTochange = {contactId: contactId };
        reset(dataTochange);
      }
    }else{
      dispatch(getInvoice(id)).then((inv) => {
        if (inv) {
          inv.invoiceDate = moment(new Date(inv.invoiceDate)).format('yyyy-MM-DD');
          inv.payDate = moment(new Date(inv.payDate)).format('yyyy-MM-DD');
          reset(inv);
          setInvoice({ ...invoice, invoiceLines: inv.invoiceLines });
          updateTotals(inv.invoiceLines);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadPDF = (e) => {
    e.preventDefault();
    const { target } = e;
    let downloadId;
    if (id){
      downloadId = id;
    }else{
      downloadId = dbInvoice.id;
    }
    target.innerHTML = '<em>Downloading...</em>';
    target.classList.add('downloading');

    axios.get(`/api/invoice/download/${downloadId}`, { responseType: 'blob' })
      .then((res) => { 
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `factuur_${downloadId}.pdf`);
      target.innerHTML = 'Download factuur';
      target.classList.remove('downloading');
    }).catch((error) => {
      target.innerHTML = 'Download factuur';
      target.classList.remove('downloading');
      dispatch(setAlert('Er is iets misgegaan bij het downloaden van de factuur (.pdf), probeer het nogmaals.', 'danger'));
    })
  }

  const mailInvoice  = (e)=> {
    e.preventDefault();
    const invoiceId = e.target.getAttribute('data-id');
    history.push(`/invoice/send/${invoiceId}`);
  }

  const showDetails = (e) => {
    e.preventDefault();
    const invoiceId = e.target.getAttribute('data-id');
    history.push(`/invoice/details/${invoiceId}`);
  }

  const getDateDaysLater = (days) => {
    var curr = new Date();
    curr.setDate(curr.getDate() + days);
    return curr.toISOString().substr(0,10);
  }

  return (
    <div>
      <SideBar {...props} />
      <div className="body-content content-wrapper">

        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="icon-calculator short" title="Facturen"> </h2>
          <div className="row">

            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Factuur</h3>
                </div>

                  <div className="box-body">
                  {dbInvoice && dbInvoice.invoiceNumber ? ( 
                    <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">Nummer</label>
                        <div className="col-8 entity-number">
                          {dbInvoice ? dbInvoice.invoiceNumber : ''}
                        </div>
                      </div>) : ''}
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Contact</label>
                      <div className="col-8">
                        <select name="contactId"
                          ref={register({ required: true })}
                          placeholder="Selecteer contact"
                          defaultValue={dbInvoice ? dbInvoice.contactId : ''}
                          style={{ border: errors.contactid ? '2px solid #D0021B' : '' }}
                          onChange={onChange} >
                            <option value="" >Selecteer een contact...</option>
                            {contacts ? contacts.map(contact => (
                              <option
                                key={contact._id}
                                value={contact._id}
                              >
                                {contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName}
                              </option>
                            )) : ''}
                        </select>
                        {errors.contactId && <span className='error'>Kies een contact</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Factuurdatum</label>
                      <div className="col-8">
                        <input
                          type="date"
                          name="invoiceDate"
                          className="form-control"
                          placeholder="Factuurdatum"
                          defaultValue={dbInvoice ? moment(new Date(dbInvoice.invoiceDate)).format('yyyy-MM-DD') : new Date().toISOString().substr(0,10)}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.invoiceDate ? '2px solid #D0021B' : '' }}
                          />
                          {errors.invoiceDate && <span className='error'>Voer een factuurdatum in</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-4 form-label">Betaaldatum</label>
                      <div className="col-8">
                        <input
                          type="date"
                          name="payDate"
                          className="form-control"
                          placeholder="Betaaldatum"
                          defaultValue={dbInvoice ? moment(new Date(dbInvoice.payDate)).format('yyyy-MM-DD') : getDateDaysLater(30)}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.payDate ? '2px solid #D0021B' : '' }}
                          />
                          {errors.payDate && <span className='error'>Voer een betaaldatum in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Betaalstatus</label>
                      <div className="col-8">
                        <select name="state"
                          ref={register({ required: true })}
                          placeholder="Selecteer betaalstatus"
                          defaultValue={dbInvoice ? dbInvoice.state : ''}
                          onChange={onChange} 
                          style={{ border: errors.state ? '2px solid #D0021B' : '' }}
                          >
                          <option value="Open">Open</option>
                          <option value="Te laat">Te laat</option>
                          <option value="Betaald">Betaald</option>
                        </select>
                          {errors.state && <span className='error'>Voer een betaaldatum in</span>}
                      </div>
                    </div>
                    
                    <LineItems
                      items={invoice ? invoice.invoiceLines : localState.invoiceLines}
                      currencyFormatter={formatCurrency}
                      addHandler={handleAddLineItem}
                      changeHandler={handleLineItemChange}
                      focusHandler={handleFocusSelect}
                      deleteHandler={handleRemoveLineItem}
                      reorderHandler={handleReorderLineItems}
                    />

                    <div className='invoice-total-container'>
                      <div>
                        <div className='invoice-value-table'>
                          <div className='invoice-row'>
                            <div className='invoice-label'>Btw (%)</div>
                            <div className='invoice-value'><input name="taxRate" type="number" step="0.01"  disabled value={taxRate} onChange={onChange} onFocus={handleFocusSelect} /></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className='invoice-value-table'>
                          <div className='invoice-row'>
                            <div className='invoice-label'>Subtotaal</div>
                            <div className='invoice-value'>{formatCurrency(localPriceWOTaxes.priceWOTaxes)}</div>
                          </div>
                          <div className='invoice-row'>
                            <div className='invoice-label'>Btw ({taxRate}%)</div>
                            <div className='invoice-value'>{formatCurrency(localTaxTotal.tax)}</div>
                          </div>
                          <div className='invoice-row'>
                            <div className='invoice-label'>Totaal</div>
                            <div className='invoice-value'>{formatCurrency(localTotalPrice.price)}</div>  
                          </div>
                        </div>
                      </div>
                    </div>

                </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary" >Opslaan</button>
                    <button onClick={downloadPDF} data-id={dbInvoice ? dbInvoice._id : ''} className="btn btn-primary" >Download factuur</button>
                    <button onClick={showDetails } data-id={dbInvoice ? dbInvoice._id : ''} className="btn btn-primary" >Toon factuur</button>
                    <button onClick={mailInvoice} data-id={dbInvoice ? dbInvoice._id : ''} className="btn btn-primary"  >Mail factuur</button>
                  </div>
              </div>
            </div>

          </div>
        </form>

        <Footer />
      </div>
    </div>);
  }
export default Invoice;