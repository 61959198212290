import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getExpenses, deleteExpense } from '../../redux/_actions/expenseAction';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import NoExpensesFound from '../NoExpensesFound/NoExpensesFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';


const ExpensesTable = ({ props }) => {

  const expenses = useSelector(state => state.expense.expenses);
  const pagination = useSelector(state => state.expense);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const removeExpense = (expenseId) => {
    confirmAlert({
      title: 'Kosten verwijderen',
      message: 'Weet je zeker dat je deze kosten wilt verwijderen?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => dispatch(deleteExpense(expenseId))
        },
        {
          label: 'Nee',
        }
      ]
    });
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getExpenses(`?offset=${pageNumber * 10 - 10}`));
  }

  async function fetchData(query) {
    dispatch(getExpenses(query));
  }

  useEffect(() => {
    let offset = 0;
    if (pagination && pagination.offset) {
      offset = pagination.offset;
    }
    fetchData(`?offset=${offset}`);
    // eslint-disable-next-line
  }, []);

  const renderExpenses = () => {
     return expenses.map((expense) => (
       <tr key={expense._id}>
         <td className="">{expense.expenseNumber}</td>
         <td>{expense.expenseDate ? moment(new Date(expense.expenseDate)).format('yyyy-MM-DD') : ''}</td>
         <td className="responsive-hidden">{expense.info}</td>
         <td className="nowrap">
           <Link className="icon-compose" to={"/expense/edit/" + expense._id} title="Aanpassen"> </Link>
           <span className="icon-trash" onClick={() => removeExpense(expense._id)} title="Verwijderen"> </span>
         </td>
       </tr>
   ));
  }


  return expenses == null || expenses.length < 1 ? (
       <NoExpensesFound contactName={props && props.contactName ?  props.contactName : ''} />
    ) : (
        <div>
        <h2 className="icon-store short" title="Kosten"> </h2><Link className="icon-add white pull-right top-margin-28" to="/expense/create" title="Nieuwe kosten">  Nieuwe kosten</Link>
          <div className="row">
            <div className="col-12">
              <div className="box box box-primary">
                <div className="box-header">
                  <h3 className="box-title">Kosten {props && props.contactName ? 'voor ' + props.contactName : ''}</h3>

                  <div className="box-tools">
                    <div className="input-group input-group-sm" style={{maxWidth: '350px'}}>
                      <input type="text" name="table_search" className="form-control pull-right" placeholder="Zoeken" />

                      <div className="input-group-btn">
                        <button type="submit" className="btn btn-default search"><FontAwesomeIcon icon={faSearch} /></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-body table-responsive no-padding">
                    <table className="table onelinertable table-hover">
                      <tbody>
                        <tr><th className="">Nummer</th><th>Datum</th><th className="responsive-hidden">Omschrijving</th><th className="icon-header"></th></tr>
                         {renderExpenses()}
                       </tbody>
                    </table>
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={10}
                      totalItemsCount={pagination.totalDocs}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
               </div>
             </div>
          </div>
        </div>
      );
}
export default ExpensesTable;