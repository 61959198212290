
export const NOTE_LOADED = 'NOTE_LOADED';
export const NOTE_LOADED_FAIL = 'NOTE_LOADED_FAIL';

export const NOTES_LOADED = 'NOTES_LOADED';
export const NOTES_LOADED_FAIL = 'NOTES_LOADED_FAIL';

export const NOTE_UPDATED_SUCCESS = 'NOTE_UPDATED_SUCCESS';
export const NOTE_UPDATED_FAIL = 'NOTE_UPDATED_FAIL';
export const NOTE_DELETED = 'NOTE_DELETED';
export const NOTE_DELETED_FAIL = 'NOTE_DELETED_FAIL';
export const NOTE_CREATED_SUCCESS = 'NOTE_CREATED_SUCCESS'; 
export const NOTE_CREATED_FAIL = 'NOTE_CREATED_FAIL';

