import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getEmail, createOrUpdateEmail, sendEmail } from '../../redux/_actions/emailAction';
import { getInvoicesList } from '../../redux/_actions/invoiceAction';
import { getContactsByType } from '../../redux/_actions/contactAction';
import { setAlert } from '../../redux/_actions/alertAction';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import { Editor } from '@tinymce/tinymce-react';
import { useForm } from 'react-hook-form';
import { useParams } from "react-router";
import moment from 'moment';

const Email = ({ props }) => {
   //TODO: invoiceId parameter vanuit Factuur Details verstuurd
  const { id, invoiceId } = useParams();
  const { register, handleSubmit, reset, errors } = useForm();
  let dbEmail = useSelector(state => state.email.email);
  let contacts = useSelector(state => state.contact.contactsByType);
  let invoices = useSelector(state => state.invoice.invoicesList);
  const dispatch = useDispatch();

  if (window.location.pathname === '/email/create') {
    dbEmail = null;
  }
  const [bodyIsValid, setBodyIsValid] = useState(true);
  const [email, setEmail] = useState({
    _id: '',
    owner: '',
    emailDate: '',
    subject: '',
    body: '',
    send: '',
    invoiceId: '',
    invoiceNumber: '',
    invoiceInfo: '',
    contactId: '',
    contactName: '',
    contactEmai: ''
  });

  const onChange = (e) =>
    setEmail({ ...email, [e.target.name]: e.target.value }
  );

  const completeData = (data) => {

    if (!window.location.pathname.startsWith('/email/create')) {
      data._id = dbEmail._id;
    }
    if (data.contactId) {
      const contact = contacts.find(c => c._id === data.contactId);
      if (contact){
        data.contactName = contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName;
        data.contactEmail = contact.emailAddress ;
      }
    }
    return data;
  }

  const resetForm = (formValues) => {
    if (formValues) {
      formValues.emailDate = moment(new Date(formValues.emailDate)).format('yyyy-MM-DD');
      reset(formValues);
      setEmail({ ...email, 'body': formValues.body });
    }
  }

  const sendInvoice = handleSubmit(data => {
      data.body = email.body;
      let isValid = email.body !== '';
      setBodyIsValid(isValid);
      if (data.emailDate === '' || data.subject === '' || data.body === '' || data.send === ''){
        dispatch(setAlert('emaildatum, titel, bericht en verzonden zijn verplicht, voer deze allemaal in.', 'danger'));
      } else {
        const fields = completeData(data);
        dispatch(sendEmail(fields)).then((inv) => {
          resetForm(inv);
        });
      }
  });
  
  const onSubmit = (data) => {
    data.body = email.body;
    let isValid = email.body !== '';
    setBodyIsValid(isValid);
    if (data.emailDate === '' || data.subject === '' || data.body === '' || data.send === ''){
      dispatch(setAlert('emaildatum, titel, bericht en verzonden zijn verplicht, voer deze allemaal in.', 'danger'));
    } else {
      const fields = completeData(data);
      dispatch(createOrUpdateEmail(fields));
    }
  }
  const handleEditorChange = (content, editor) => {
    if (content !== ''){
      setBodyIsValid(true);
    }
    setEmail({ ...email, 'body': content });
  }

  useEffect(() => {
    dispatch(getContactsByType('Klant'));
    dispatch(getInvoicesList());
    
    if (!window.location.pathname.startsWith('/email/create') && !window.location.pathname.startsWith('/invoice/send/')) {
      dispatch(getEmail(id)).then((inv) => {
        resetForm(inv);
      })
    }

    //TODO: Vanuit Factuur Details verstuurd
    if (invoiceId){
      const dataToChange = { invoiceId: invoiceId };
      reset(dataToChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>
      <SideBar {...props} />
      <div className="body-content content-wrapper">

        <form onSubmit={handleSubmit(onSubmit)}>  
          <h2 className="icon-envelope short" title="Email"> </h2>
          <div className="row">

            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Email</h3>
                </div>

                  <div className="box-body">
                    
                    {dbEmail && dbEmail.emailNumber ? (
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Nummer</label>
                      <div className="col-8 contact-number">
                        {dbEmail ? dbEmail.emailNumber : ''}
                      </div>
                    </div>) : ''}
                    
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Contact</label>
                      <div className="col-8">
                        <select name="contactId"
                          ref={register({ required: true })}
                          placeholder="Selecteer contact"
                          defaultValue={dbEmail ? dbEmail.contactId : ''}
                          style={{ border: errors.contactId ? '2px solid #D0021B' : '' }}
                          onChange={onChange} >
                            <option value="" >Selecteer een contact...</option>
                            {contacts ? contacts.map(contact => (
                              <option
                                key={contact._id}
                                value={contact._id}
                              >
                                {contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName}
                              </option>
                            )) : ''}
                        </select>
                        {errors.contactId && <span className='error'>Kies een contact</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Datum</label>
                      <div className="col-8">
                        <input
                          type="date"
                          name="emailDate"
                          className="form-control"
                          placeholder="Emaildatum"
                          defaultValue={dbEmail ? moment(new Date(dbEmail.emailDate)).format('yyyy-MM-DD') : new Date().toISOString().substr(0,10)}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.emailDate ? '2px solid #D0021B' : '' }}
                          />
                          {errors.emailDate && <span className='error'>Voer een emaildatum in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Titel</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Titel"
                          defaultValue={dbEmail ? dbEmail.subject : ''}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.subject ? '2px solid #D0021B' : '' }}
                          />
                          {errors.subject && <span className='error'>Voer een titel in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Bericht</label>
                      <div className="col-8" >
                        <div className="editor-container" style={{border: bodyIsValid ? '' : '2px solid #D0021B' }}> 
                        <Editor
                          apiKey="0vsmig4gbuexbytk35gc77h3hej4hav0azkd8qrvorjwbvts"
                          initialValue={email ? email.body : ''}
                          value={email ? email.body : ''}
                          style={{border: bodyIsValid ? '' : '2px solid #D0021B' }}
                          name="body"
                          init={{
                            height: 300,
                            width: 405,
                            content_style:  "body { font-family: 'Lato','Helvetica Neue',Helvetica,Arial,sans-serif; color: #484848;}",
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                              'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                          }}
                          onEditorChange={onChange && handleEditorChange}
                        />
                        </div>
                        <span className={bodyIsValid ? 'hide' : 'error'}>Voer een bericht in</span> 
                        
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Verzonden</label>
                      <div className="col-8">
                        <select name="send"
                          ref={register({ required: true })}
                          placeholder="Selecteer verzendstatus"
                          defaultValue={dbEmail ? dbEmail.send : ''}
                          style={{ border: errors.send ? '2px solid #D0021B' : '' }}
                          onChange={onChange} >
                              <option value="false" >Nee</option>
                              <option value="true" >Ja</option>
                        </select>
                        {errors.send && <span className='error'>Geef de verzendstatus aan</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Factuur</label>
                      <div className="col-8">
                        <select name="invoiceId"
                          ref={register({ required: false })}
                          placeholder="Selecteer factuur"
                          defaultValue={dbEmail ? dbEmail.invoiceId : ''}
                          onChange={onChange} 
                          >
                            <option value='' >Selecteer een factuur...</option>
                            {invoices ? invoices.map(invoice => (
                              <option
                                key={invoice._id}
                                value={invoice._id}
                              >
                                {invoice.invoiceNumber}
                              </option>
                            )) : ''}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button id="save" type="submit" className="btn btn-primary" >Opslaan</button>
                    <button id="send" onClick={sendInvoice}  className="btn btn-primary"  >Opslaan & Versturen</button> 
                  </div>
              </div>
            </div>

          </div>
        </form>

      </div>
      <Footer />
    </div>
  )
}
export default Email
