import Footer from '../Footer/Footer';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../redux/_actions/authAction';
import { setAlert } from '../../redux/_actions/alertAction'
import { CLEAR_ERRORS } from '../../redux/alertTypes';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form';

const Login = ({ history }) => {

  const { register, handleSubmit, errors } = useForm();
  const state = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });



  const onChange = (e) =>
    setLoginData({ ...loginData, [e.target.name]: e.target.value }
    );

  useEffect(() => {
    if (state.isAuthenticated) {
      history.push('/contacts');
    }
    if (state.error === 'Combinatie van naam en wachtwoord is niet valide') {
      dispatch(setAlert(state.error, 'danger'));
      dispatch({ type: CLEAR_ERRORS });
    }
    console.log(state);
    // eslint-disable-next-line
  }, [state.isAuthenticated, state.error]);

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');

  const onSubmit = (data) => {
    // e.preventDefault();
    if (data.email === '' || data.password === '') {
      dispatch(setAlert('Email en wachtwoord zijn verplicht', 'danger'));
    } else {
      dispatch(login(data.email, data.password));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="body-content content-wrapper">
        <div className="row">
          <div className="login-row">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title"><FontAwesomeIcon icon={faSignInAlt} /> Inloggen</h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    autoComplete="username"
                    name="email"
                    placeholder="Email"
                    defaultValue={loginData.email}
                    onChange={onChange}
                    ref={register({ required: true })}
                    style={{ border: errors.email ? '2px solid #D0021B' : '' }}
                  />
                  {errors.email && <span className='error'>Voer je email adres in</span>}
                  {/* <input placeholder="Email" type="email" id="email" className="form-control" autoComplete="username" value={email} onChange={e => setEmail(e.target.value)} /> */}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    autoComplete="password"
                    name="password"
                    placeholder="Wachtwoord"
                    defaultValue={loginData.password}
                    onChange={onChange}
                    ref={register({ required: true })}
                    style={{ border: errors.password ? '2px solid #D0021B' : '' }}
                  />
                  {errors.password && <span className='error'>Voer een wachtwoord in</span>}
                  {/* <input placeholder="Password" type="password" id="password" className="form-control" autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} /> */}
                </div>
              </div>

              <div className="box-footer-nomargin centered">
                <button type="submit" className="btn btn-primary block">Login</button>
              </div>
              <div className="centered">
                <p>Registreer je om in te loggen <Link to="/subscribe">Registreren</Link></p>
              </div>

            </div>
          </div>
        </div>
        <Footer />
      </div>
    </form>
  );

}
export default Login