import React, { Component } from 'react'
import PropTypes from 'prop-types'

class LineItem extends Component {
  
  render = () => {
    const { index, description, numberOfItems, priceWOTaxes } = this.props

    return (
      <div className='lineitem-row'>
        <div>{index + 1}</div>
        <div><input name="description" type="text" value={description} onChange={this.props.changeHandler(index)} /></div>
        <div><input name="numberOfItems" type="number" step="1" value={numberOfItems} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} /></div>
        <div ><input name="priceWOTaxes" type="number" step="0.01" min="0.00" max="9999999.99" value={priceWOTaxes} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} /></div>
        <div >{this.props.currencyFormatter( numberOfItems * priceWOTaxes )}</div>
        <div>
          <button type="button"
            className='lineitem-button-delete'
            onClick={this.props.deleteHandler(index)}
          >
            <i className="icon-trash short" />
        </button>
      </div>
      <input type="hidden" name="totalLinePrice"  onChange={this.props.changeHandler(index)} value={numberOfItems * priceWOTaxes} />
      </div>
    )
  }
}

export default LineItem

LineItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  numberOfItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceWOTaxes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}


