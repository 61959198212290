
export const CONTACT_LOADED = 'CONTACT_LOADED';
export const CONTACT_LOADED_FAIL = 'CONTACT_LOADED_FAIL';

export const CONTACTS_LOADED = 'CONTACTS_LOADED';
export const CONTACTS_LOADED_FAIL = 'CONTACTS_LOADED_FAIL';
export const CONTACTS_BY_TYPE_LOADED = 'CONTACTS_BY_TYPE_LOADED';
export const CONTACTS_BY_TYPE_LOADED_FAIL = 'CONTACTS_BY_TYPE_LOADED_FAIL';

export const CONTACT_UPDATED_SUCCESS = 'CONTACT_UPDATED_SUCCESS';
export const CONTACT_UPDATED_FAIL = 'CONTACT_UPDATED_FAIL';
export const CONTACT_DELETED = 'CONTACT_DELETED';
export const CONTACT_DELETED_FAIL = 'CONTACT_DELETED_FAIL';
export const CONTACT_CREATED_SUCCESS = 'CONTACT_CREATED_SUCCESS'; 
export const CONTACT_CREATED_FAIL = 'CONTACT_CREATED_FAIL';

