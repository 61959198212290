import React, { useState } from 'react';
//import { Document, Page, pdfjs } from 'react-pdf';
import { Document, Page, pdfjs  } from 'react-pdf/dist/esm/entry.webpack';

const PdfViewer = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); 


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = (e) => {
    e.preventDefault();
    changePage(-1);
  }

  const nextPage = (e) => {
    e.preventDefault();
    changePage(1);
  }




  return (
    <>
    <div>
      <Document
        file={pdf}
        options={{ workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js` }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
    <br />
    <div className="centered pagination-container" disabled={pageNumber >= numPages}>
      <button className="icon-button left" disabled={pageNumber <= 1} onClick={previousPage}></button>
      <span className="pagination-text" disabled={pageNumber >= numPages}>Pagina {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"} </span> 
      <button className="icon-button right" disabled={pageNumber >= numPages} onClick={nextPage}></button>
    </div>
    </>
  );
}
export default PdfViewer;
