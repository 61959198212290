import React from 'react';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import ExpensesTable from '../../components/ExpensesTable/ExpensesTable.js';


const Expenses = ({ props }) => {

      return (
        <div>
          <SideBar {...props} />
          <div className="body-content content-wrapper">
            <ExpensesTable  />
            <Footer />
          </div>
        </div>
      )
}
export default Expenses