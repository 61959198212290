import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import LineItem from './LineItem'

//import { MdAddCircle as AddIcon } from 'react-icons/md'
//import styles from './LineItems.module.scss'


class LineItems extends Component {

  handleDragEnd = (result) => {

    if (!result.destination) return

    // helper function to reorder result (src: react-beautiful-dnd docs)
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      return result
    }

    // perform reorder
    const lineItems = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    )

    // call parent handler with new state representation
    this.props.reorderHandler(lineItems)

  }

  render = () => {
    const styles = { 
                    listDraggingOver: 'list-drag-over'
                  };
    const {items, addHandler, reorderHandler, ...functions} = this.props

    return (
      <div className='lineitems'>
        <div className='lineitem-add-button-row'>   
          <button className="btn btn-primary  no-right-margin" onClick={addHandler}>
            <i className="icon-add text-right" /> Nieuwe regel
          </button> 
        </div>
        <div className='lineitems-container'>
          <div className='lineitems-grid-table'>

            <div className='lineitems-headers'>
              <div>#</div>
              <div>Omschrijving</div>
              <div>Aantal</div>
              <div>Prijs</div>
              <div>Totaal</div>
              <div></div>
            </div>

            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={snapshot.isDraggingOver ? styles.listDraggingOver : ''}
                  >
                    {this.props.items.map((item, i) => (
                      <Draggable key={item.id ? item.id : item._id} draggableId={i.toString()} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                            className={snapshot.isDragging ? 'row-is-dragging' : ''}
                          >
                            <LineItem
                              style={{color: 'red'}}
                              key={i + (item.id ? item.id : item._id)} index={i} name={item.name}
                              description={item.description} numberOfItems={item.numberOfItems} priceWOTaxes={item.priceWOTaxes}
                              {...functions}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

          </div>
        </div>
        </div>
    )
  }
}

export default LineItems

LineItems.propTypes = {
  items: PropTypes.array.isRequired,
  currencyFormatter: PropTypes.func.isRequired,
  addHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  focusHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  reorderHandler: PropTypes.func.isRequired,
}


