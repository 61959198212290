import React from 'react'
import EmailsTable from '../../components/EmailsTable/EmailsTable';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';


const Emails = ({ props, history }) => {
  return (
    <div>
      <SideBar {...props} />
      <div className="body-content content-wrapper">
        <EmailsTable />
        <Footer />
      </div>
    </div>
  )
}
export default Emails 