import React from 'react';
import PropTypes from 'prop-types';
import { Nav,  NavDropdown } from 'react-bootstrap';
import {useDispatch} from 'react-redux'
import {LOGOUT} from '../../redux/authTypes'


const AuthenticatedNavigation = ({ name }) => {

  const dispatch = useDispatch();

  const logout = () => {
    dispatch({type: LOGOUT});
  }

  return(
    <Nav className="fullwidth">
      <Nav.Item>
        <Nav.Link eventKey="2.1" href="/contacts">
          Contacten
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="2.2" href="/invoices">
          Facturen
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="2.3" href="/expenses">
          Kosten
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
      <Nav.Link eventKey="2.4" href="/emails">
        Emails
      </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="2.5" href="/notes">
          Notities
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="2.6" href="/settings">
          Instellingen
        </Nav.Link>
      </Nav.Item>
      <NavDropdown className="justify-content-end navbar-right" title={name.length < 23 ? name : name.substr(0, 22) } id="user-nav-dropdown" >
        <NavDropdown.Item eventKey="1.2" href="/profile">
            Profiel
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.4" onClick={logout} >Uitloggen</NavDropdown.Item>
      </NavDropdown>
  </Nav>
)};

AuthenticatedNavigation.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AuthenticatedNavigation;
