
export const EMAIL_LOADED = 'EMAIL_LOADED';
export const EMAIL_LOADED_FAIL = 'EMAIL_LOADED_FAIL';

export const EMAILS_LOADED = 'EMAILS_LOADED';
export const EMAILS_LOADED_FAIL = 'EMAILS_LOADED_FAIL';

export const EMAIL_UPDATED_SUCCESS = 'EMAIL_UPDATED_SUCCESS';
export const EMAIL_UPDATED_FAIL = 'EMAIL_UPDATED_FAIL';
export const EMAIL_DELETED = 'EMAIL_DELETED';
export const EMAIL_DELETED_FAIL = 'EMAIL_DELETED_FAIL';
export const EMAIL_CREATED_SUCCESS = 'EMAIL_CREATED_SUCCESS'; 
export const EMAIL_CREATED_FAIL = 'EMAIL_CREATED_FAIL';

export const EMAIL_SEND_SUCCESS = 'EMAIL_SEND_SUCCESS';
export const EMAIL_SEND_FAIL = 'EMAIL_SEND_FAIL';

