import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getExpense, createOrUpdateExpense } from '../../redux/_actions/expenseAction';
import { getContactsByType } from '../../redux/_actions/contactAction';
import { setAlert } from '../../redux/_actions/alertAction';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import { useForm } from 'react-hook-form';
import { useParams } from "react-router";
import moment from 'moment';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';
import PdfViewer from '../../components/PdfViewer/PdfViewer';
//const { v4: uuidv4 } = require('uuid');

const Expense = ({ props }) => {

  const { id } = useParams();
  const { register, handleSubmit, reset, errors } = useForm();
  const maxSize = 1048576;

  
  const onDrop = useCallback( acceptedFiles => {

    const upload = async (file) => {
      console.log('in!');
  
      let currentFile ;
      if (file){
        currentFile = file;
      } else {
        return;
      }
  
      let formData = new FormData();
      formData.append("file", currentFile);
  
      try {
        const config = {header: {'Content-Type': 'multipart/form-data'}}  
        const res = await axios.post(`/api/document`, formData, config);
  
        if (res && res.data && res.data.data.fileLocation){
          const fileInput = document.getElementById('expenseFile');
          if (fileInput) {
            fileInput.value = res.data.data.fileLocation;
          }
          const img = document.getElementById('expenseFileDisplay');
          if (img) {
            img.src = '/api/document/' + res.data.data.fileLocation;
          }
        }else{
          console.log('no response');
        }
      } catch (err) {
        if(err && err.response && err.response.data){
          console.log(err.response.data.message);
        } else {
          console.log(err);
        }
        dispatch(setAlert('Er is iets misgegaan bij het uploaden van het document, probeer het nogmaals.', 'danger'));
      }     
    }

    upload( acceptedFiles[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles, open } = useDropzone({
    onDrop,
    accept:  'image/*,application/pdf',
    minSize: 0,
    maxSize,
    maxFiles:1
  });

  const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  let dbExpense = useSelector(state => state.expense.expense);
  let contacts = useSelector(state => state.contact.contactsByType);
  const dispatch = useDispatch();
  //TODO: Get from db
  const taxRate = 21;

  if (window.location.pathname === '/expense/create') {
    dbExpense = null;
  }

  // const [localPriceWOTaxes, setPriceWOTaxes] = useState({ priceWOTaxes: 0.00} );
  // const [localTaxTotal, setTaxTotal] = useState({ tax: 0.00} );
  // const [localTotalPrice, setTotalPrice] = useState({ price: 0.00} );

  const [expense, setExpense] = useState({
    _id: '',
    contactId: '',
    contactName: '',
    expenseNumber: '',
    expenseDate: '',
    info: '',
    tax: 0.00,
    priceWOTaxes: 0,
    price:0,
    state: '',
    expenseFile: '',
    tenantId:''
  });

  const onChange = (e) =>
    setExpense({ ...expense, [e.target.name]: e.target.value }
  );

  const onSubmit = (data) => {
    if (data.expenseDate === '' ||
      data.priceWOTaxes === '' ){
      dispatch(setAlert('Kostendatum en prijs ex btw zijn verplicht, voer deze allemaal in.', 'danger'));
    } else {
      // if (localPriceWOTaxes.priceWOTaxes > 0){
      //   data.priceWOTaxes = localPriceWOTaxes.priceWOTaxes;
      // }
      // if (localTaxTotal.tax > 0){
      //   data.tax = localTaxTotal.tax;
      // }
      // if (localTotalPrice.price > 0){
      //   data.price = localTotalPrice.price;
      // }
      if (data.contactId) {
        const contact = contacts.find(c => c._id === data.contactId);
        if (contact){
          data.contactName = contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName;
        }
      }
      
      if (window.location.pathname !== '/expense/create') {
        data._id = dbExpense._id;
      }
      console.log(data);
      dispatch(createOrUpdateExpense(data));
    } 
  }
  
  const handleFocusSelect = (e) => {
    e.target.select()
  }

  useEffect(() => {
    dispatch(getContactsByType('Leverancier'));
    if (window.location.pathname === '/expense/create') {
    }else{
      dispatch(getExpense(id)).then((inv) => {
        if (inv) {
          inv.expenseDate = moment(new Date(inv.expenseDate)).format('yyyy-MM-DD');
          reset(inv);
          setExpense({ ...expense, expenseFile: inv.expenseFile });
          setExpense({ ...expense, tenantId: inv.tenantId });
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const collapse = () => {
    const elem = document.getElementById('image-container');
    if (elem) {
      elem.classList.toggle('full-width');
      const button = document.getElementById('expand-pdf-button-expanded');
      if (button) { button.classList.toggle('expand-pdf-button-expanded')};
    }
  }
  // const updateTotals = (lineItems) => {
  //   const lineItemTotal = lineItems.reduce((prev, cur) => (prev + (cur.numberOfItems * cur.priceWOTaxes)), 0);
  //   if (localPriceWOTaxes.priceWOTaxes !== lineItemTotal){
  //     const taxTotal = lineItemTotal * (taxRate / 100);
  //     const grandTotal = lineItemTotal + taxTotal;
  //     setPriceWOTaxes({ ...localPriceWOTaxes, priceWOTaxes: parseFloat(lineItemTotal)});
  //     setTaxTotal({ ...localTaxTotal, tax: parseFloat(taxTotal)});
  //     setTotalPrice({ ...localTotalPrice, price: parseFloat(grandTotal)}); 
  //   }
  // }

  // const formatCurrency = (amount) => {
  //   return (new Intl.NumberFormat('NL-nl', {
  //     style: 'currency',
  //     currency: 'EUR',
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2
  //   }).format(amount))
  // }

  // const getImageOrPdfComponent = (file) => {
  //   const extension = file.split(/[#?]/)[0].split('.').pop().trim();

  //   switch(extension.toLowerCase()) {
  //     case "pdf":   return <PdfViewer pdf={`/api/document/${file}`} />;
  //     default:      return <>
  //                           <img className="expense-image" alt="Klik hier om de afbeelding aan te passen" id="expenseFileDisplay" name="expenseFileDisplay" src={`/api/document/${file}`} /> 
  //                           <div className="expense-image-edit-msg" onClick={open} title="Klik hier of op de afbeelding om deze aan te passen">Aanpassen</div>
  //                          </>;
  //   }
  // }
  
  return (
    <div>
      <SideBar {...props} />
      <div className="body-content content-wrapper">

        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="icon-store short" title="Kosten"> </h2>

          <div className="row">

            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Kosten</h3>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="box-body">
                      {dbExpense && dbExpense.expenseNumber  ? (
                      <div className="form-group row">
                        <label className="col-form-label col-4 form-label">Nummer</label>
                        <div className="col-8 entity-number">
                          {dbExpense ? dbExpense.expenseNumber : ''}
                        </div>
                      </div>) : ''}
                    
                      <div className="form-group row">
                        <label className="col-form-label col-4 form-label">Contact</label>
                        <div className="col-8">
                          <select name="contactId"
                            ref={register({ required: false })}
                            placeholder="Selecteer leverancier"
                            defaultValue={dbExpense? dbExpense.contactId : ''}
                            style={{ border: errors.contactId ? '2px solid #D0021B' : '' }}
                            onChange={onChange} >
                              <option value="" >Selecteer een contact...</option>
                              {contacts ? contacts.map(contact => (
                                <option
                                  key={contact._id}
                                  value={contact._id}
                                >
                                  {contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName}
                                </option>
                              )) : ''}
                          </select>
                          {errors.contactId && <span className='error'>Kies een contact</span>}
                        </div>
                      </div>
                      <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">Kostendatum</label>
                        <div className="col-8">
                          <input
                            type="date"
                            name="expenseDate"
                            className="form-control"
                            placeholder="Kostendatum"
                            defaultValue={dbExpense ? moment(new Date(dbExpense.expenseDate)).format('yyyy-MM-DD') : new Date().toISOString().substr(0,10)}
                            onChange={onChange}
                            ref={register({ required: true })}
                            style={{ border: errors.expenseDate ? '2px solid #D0021B' : '' }}
                            />
                            {errors.expenseDate && <span className='error'>Voer een kostendatum in</span>}
                        </div>
                      </div>
                      <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">Omschrijving</label>
                        <div className="col-8">
                          <input
                            type="text"
                            name="info"
                            className="form-control"
                            placeholder="Omschrijving"
                            defaultValue={dbExpense ? dbExpense.info : ''}
                            onChange={onChange}
                            ref={register({ required: false })}
                          />
                        </div>
                      </div>

                      <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">Prijs exc. BTW</label>
                        <div className="col-8">
                          <input
                            type="text"
                            name="priceWOTaxes"
                            className="form-control"
                            placeholder="Prijs exc. BTW"
                            defaultValue={dbExpense ? dbExpense.priceWOTaxes : ''}
                            onChange={onChange}
                            ref={register({ required: false })}
                          />
                        </div>
                      </div>

                      <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">BTW ({taxRate}%)</label>
                        <div className="col-8">
                          <input
                            type="text"
                            name="tax"
                            className="form-control"
                            placeholder="BTW bedrag"
                            defaultValue={dbExpense ? dbExpense.tax : ''}
                            onChange={onChange}
                            ref={register({ required: false })}
                          />
                        </div>
                      </div>

                      <div className="form-group required row">
                        <label className="col-form-label col-4 form-label">Totaal</label>
                        <div className="col-8">
                          <input
                            type="text"
                            name="price"
                            className="form-control"
                            placeholder="Totaal bedrag (inc. BTW)"
                            defaultValue={dbExpense ? dbExpense.price : ''}
                            onChange={onChange}
                            ref={register({ required: false })}
                          />
                        </div>
                      </div>
                      <div className='invoice-total-container'>
                        <div>
                          <div className='invoice-value-table'>
                            <div className='invoice-row'>
                              <div className='invoice-label'>Btw (%)</div>
                              <div className='invoice-value'><input name="taxRate" type="number" step="0.01"  value={taxRate} onChange={onChange} onFocus={handleFocusSelect} /></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-6">
                    <div id="image-container" className={dbExpense && dbExpense.expenseFile ? 'expense-image-container' : 'expense-image-drop' }>
                      <button id="expand-pdf-button-expanded" className="expand-pdf-button" onClick={collapse}></button>
                        <div {...getRootProps({className: 'dropzone'})  } >
                          <input {...getInputProps()} />
                          {dbExpense && dbExpense.expenseFile  ? (
                            dbExpense.expenseFile.toLowerCase().endsWith('.pdf') ? (
                              <PdfViewer pdf={`/api/document/${dbExpense.expenseFile}`} />
                            ) : (
                              <>
                                <img className="expense-image" alt="Klik hier om de afbeelding aan te passen" id="expenseFileDisplay" name="expenseFileDisplay" src={`/api/document/${dbExpense.expenseFile}`} /> 
                                <div className="expense-image-edit-msg" onClick={open} title="Klik hier of op de afbeelding om deze aan te passen">Aanpassen</div>
                              </>
                            )
                          ) : ( 
                            <p>
                              {!isDragActive && 'Sleep je bon hierheen, of klik om een bestand te selecteren'}
                              {isDragActive && !isDragReject && 'Laat je bestand hier los!'}
                              {isDragReject && 'Dit bestandstype wordt niet geaccepteerd, sorry'}
                              {isFileTooLarge && (
                                <div className="text-danger mt-2">
                                  Bestand is te groot!
                                </div>
                              )}
                            </p>
                          )}
                           
                        </div>

                        <input
                          id="expenseFile"
                          type="hidden"
                          name="expenseFile"
                          defaultValue={dbExpense ? dbExpense.expenseFile : ''}
                          onChange={onChange}
                          ref={register({ required: false })}
                          />
                    </div>

                  </div>


                </div>
                <div className="box-footer">
                  <button type="submit" className="btn btn-primary" >Opslaan</button>
                </div>
              </div>
            </div>

          </div>
        </form>

        <Footer />
      </div>
    </div>);

}
export default Expense