import { PAYMENT_SUBSCIPTION_UPDATED_SUCCESS, 
         PAYMENT_SUBSCIPTION_UPDATED_FAIL, 
         PAYMENT_SUBSCIPTION_LOADED_SUCCESS,
         PAYMENT_SUBSCIPTION_LOADED_FAIL,
         PAYMENT_SUBSCIPTION_BY_ORDERID_LOADED_SUCCESS,
         PAYMENT_SUBSCIPTION_BY_ORDERID_LOADED_FAIL  } from '../paymentTypes'
import axios from 'axios';
import { setAlert } from './alertAction'


export const createSubscription = (data) => {
  return async dispatch => {
    try {
          let config;
      
          // if (data && data.token){
          //   config = { header: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + data.token } };
          //   console.log('Token added to Header:           '+ data.token);
          // }else{
            config = { header: { 'Content-Type': 'application/json' } };
          // }
          
          const res = await axios.post(`/api/payment/mollie/subscription`, data, config);
          dispatch({type: PAYMENT_SUBSCIPTION_UPDATED_SUCCESS, payload: res.data.data});
          return res.data.data;
        } catch (err) {
          if(err && err.response && err.response.data){
            dispatch({type: PAYMENT_SUBSCIPTION_UPDATED_FAIL, payload: err.response.data.message});
          } else {
            console.log(err);
            dispatch({type: PAYMENT_SUBSCIPTION_UPDATED_FAIL, payload: err});
          }
          dispatch(setAlert('Er is iets misgegaan bij het opslaan van het profiel.', 'danger'));
        }     
  }
}

export const getSubscription = (id) => {
  return async dispatch => {
    try {
          const config = { header: { 'Content-Type': 'application/json' } }
          const res = await axios.get(`/api/payment/subscription${id}`, config);
          dispatch({type: PAYMENT_SUBSCIPTION_LOADED_SUCCESS, payload: res.data.data});
          return res.data.data;
        } catch (err) {
          if(err && err.response && err.response.data){
            dispatch({type: PAYMENT_SUBSCIPTION_LOADED_FAIL, payload: err.response.data.message});
          } else {
            console.log(err);
            dispatch({type: PAYMENT_SUBSCIPTION_LOADED_FAIL, payload: err});
          }
          dispatch(setAlert('Er is iets misgegaan bij het ophalen van het abonnement.', 'danger'));
        }     
  }
}


export const getSubscriptionByOrderId = (id) => {
  return async dispatch => {
    try {
          const config = { header: { 'Content-Type': 'application/json' } }
          const res = await axios.get(`/api/payment/subscription/order/${id}`, config);
          dispatch({type: PAYMENT_SUBSCIPTION_BY_ORDERID_LOADED_SUCCESS, payload: res.data.data});
          return res.data.data;
        } catch (err) {
          if(err && err.response && err.response.data){
            dispatch({type: PAYMENT_SUBSCIPTION_BY_ORDERID_LOADED_FAIL, payload: err.response.data.message});
            return { error: err.response.data.message} ;
          } else {
            console.log(err);
            dispatch({type: PAYMENT_SUBSCIPTION_BY_ORDERID_LOADED_FAIL, payload: err});
            if (err && err.message){
              return { error: err.message } ;
            }
            return { error: 'error' } ;
          }
        }     
  }
}