import React, { useEffect } from 'react'
import {  useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { getSubscriptionByOrderId } from '../../redux/_actions/paymentAction';
import { setAlert } from '../../redux/_actions/alertAction';
//import {LOGOUT} from '../../redux/authTypes';

const Success = ({ props }) => {

  let query = new URLSearchParams(useLocation().search);
  const orderId = query.get('orderId');
  const dispatch = useDispatch();

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

  let numberOfChecks = 0;

  const checkPaymentStatus = (id) => {
    dispatch(getSubscriptionByOrderId(id)).then((subscription) => {

      if (subscription && subscription.paymentState === 'paid'){
        dispatch(setAlert('De betaling is binnengekomen, bedankt!', 'info'));
      } else{
        if (subscription.error){
          //dispatch({type: LOGOUT});
          //TODO: Remove user etc...
          //dispatch(setAlert('De betaling is mislukt', 'danger'));
        }else{
          console.log(numberOfChecks);
          if (numberOfChecks < 2){
            callCheckAfterDelay(id);
          }else{
            //dispatch({type: LOGOUT});
            //TODO: Remove user etc...
         //   dispatch(setAlert('De betaling is mislukt.', 'danger'));
          }
          numberOfChecks++;
        }
      }
    });
  }

  const callCheckAfterDelay = async (id) => {
    await sleep(5000);
    checkPaymentStatus(id);
  }


  useEffect(() => {
    if (orderId)
      checkPaymentStatus(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);
  
  
  return (  
    <div>
      <div className="body-content content-wrapper">
        <div className="jumbotron">
          <img className="logohome" src="/assets/img/books_64.png" alt="paperwork logo" /><h2 className="brand">Welkom bij paper-work!</h2>
          <p className="lead">Wat fijn dat je hier bent.</p>

          <p>Omdat wij het belangrijk vinden dat jij het meeste uit paper-work haalt vinden wij het belangrijk je te helpen om dat voor elkaar te krijgen. <br />
           Hier volgen een aantal stappen die je helpen zodat alles werkt zoals gewenst.</p>
          <ul>
            <li><Link to="/settings" className="no-styling">Instellingen invoeren</Link></li>
            <li>Contacten invoeren (in ieder geval een aantal), dit zijn klanten en leveranciers</li>
          </ul>
          <p>Nadat je de instellingen en een aantal belangrijke klanten hebt ingevoerd, kan je facturen, kosten en de overige functionaliteiten gaan uitproberen.
            <br /> Veel succes!
          </p>
          <p><Link to="/settings" className="btn btn-primary btn-large">Stap 1: Vul de instellingen in</Link>  Vul de belangrijkste instellingen in</p>

        </div>

        {/* <div className="content-centered">
          <div className="row">
            <div className="col-md-6">
              <h2>Nog geen account?</h2>
              <p>
                Maak een account aan en registreer je klanten en contactmomenten
                        </p>
              <p><a className="btn btn-default" href="/register">Maak mijn account </a></p>
            </div>
            <div className="col-md-6 padding-bottom-35">
              <h2>Informatie</h2>
              <p>Wil je meer informatie?</p>
              <p><a className="btn btn-default" href="/about">Informatie</a></p>
            </div>
          </div>
        </div>*/}
      </div> 
      <div className="content-centered no-sidebar">
        <Footer />
      </div>
    </div>
  )
}
export default Success
