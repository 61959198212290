import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getInvoice } from '../../redux/_actions/invoiceAction';
import { getContact } from '../../redux/_actions/contactAction';
import { getSettings } from '../../redux/_actions/settingsAction';
import PropTypes from 'prop-types';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import InvoiceReport from '../../components/InvoiceReport/InvoiceReport';
import { useParams } from "react-router";


const InvoiceDetails = ({ props }) => {
  const { id } = useParams();
  let invoice = useSelector(state => state.invoice.invoice);
  let contact = useSelector(state => state.contact.contact);
  let settings = useSelector(state => state.settings.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings()).then((set) => {
      if (set) {
      }
    })

    dispatch(getInvoice(id)).then((inv) => {
      if (inv) {
        dispatch(getContact(inv.contactId)).then((con) => {
          if (con) {
          }
        })
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
        <div>
          <SideBar {...props} />
          <div className="body-content content-wrapper invoice">
                <h2 className="icon-calculator short" title="Facturen"> </h2>
                <div className="row">

                  <div className="col-md-12 pdf">
                    <div className="box box-primary ">
                      <div id="pageHeader" className="title">
                        <div className="box-header with-border">
                          <h3 className="box-title">Factuur</h3>
                        </div>
                      </div>


                  <InvoiceReport invoice={invoice} settings={settings} contact={contact}  />

                    </div>
                  </div>
                </div>
                <Footer />
            </div>
        </div>
  )
}
export default InvoiceDetails

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
  contact: PropTypes.object,
  companyName: PropTypes.string
};
