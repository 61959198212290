import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/_actions/alertAction';
import { register as authRegister } from '../../redux/_actions/authAction';
import { createSubscription } from '../../redux/_actions/paymentAction';
import Footer from '../../components/Footer/Footer';
import { useForm } from 'react-hook-form';
import setAuthToken from '../../utils/setAuthToken';
//import {LOGOUT} from '../../redux/authTypes';

const Subscribe = () => {

  const dispatch = useDispatch();
  //dispatch({type: LOGOUT});
  const { register, handleSubmit, errors } = useForm();
  const [customer, setCustomer] = useState({
    email: '',
    name: '',
    companyName: '',
    password: '',
    plan: '',
    price: '',
    currency: ''
  });

  const onChange = (e) =>
    setCustomer({ ...customer, [e.target.name]: e.target.value }
  );

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));


  const onSubmit = async (data) => {

    if (data.email === '' || data.name === '') {
      dispatch(setAlert('Naam en email zijn verplicht', 'danger'));
    } else {
      try {
        dispatch(authRegister(data))
        .then( response => {
          if (response && response.success) {
            data._id = response.userId;
            data.plan = 'Essentials';
            data.price = '7.50';
            data.currency = 'EUR';
            data.organizationId = response.organizationId;
            return data;
          }
        })
        .then( result => { 
          handleSubscription(result);
        });
        
      } catch (err) {
        console.log(err);
        dispatch(setAlert('Oeps, er is iets misgegaan met het doorverwijzen naar het betaalgedeelte', 'danger'));
      }
    }
  }

  const handleSubscription = async (data) => {
    await sleep(1000);       
    setAuthToken(localStorage.token);
    createSubscriptionAndShowCheckout(data);
  }

  const createSubscriptionAndShowCheckout = async (data) => {
    
    dispatch(createSubscription(data)).then(subscription => {
      if (subscription) {
        console.log(subscription);
        if (subscription && subscription.paymentUrl) {
          window.location.href = subscription.paymentUrl;
        } else {
          dispatch(setAlert('Oeps, de link naar het betaalgedeelte is niet geldig, daarom kunnen we je niet doorverwijzen', 'danger'));
        }
      }
    })
  }

  return (
    <div className="body-content content-wrapper">
      <div className="content-centered padding-top-15 subscribe">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="icon-store short" title="Store"> </h2>
          <div className="row">

            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Inschrijven</h3>
                </div>

                <div className="box-body">

                  <div className="form-group required row">
                    <label className="col-form-label col-4 form-label">Naam</label>
                    <div className="col-8">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Naam"
                        autoComplete="name"
                        defaultValue={customer.name}
                        onChange={onChange}
                        ref={register({ required: true })}
                        style={{ border: errors.name ? '2px solid #D0021B' : '' }}
                      />
                      {errors.name && <span className='error'>Voer je naam in</span>}
                    </div>
                  </div>

                  <div className="form-group required row">
                    <label className="col-form-label col-4 form-label">Email</label>
                    <div className="col-8">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        autoComplete="email"
                        defaultValue={customer.email}
                        onChange={onChange}
                        ref={register({ required: true })}
                        style={{ border: errors.email ? '2px solid #D0021B' : '' }}
                      />
                      {errors.email && <span className='error'>Voer je email in</span>}
                    </div>
                  </div>

                  <div className="form-group required row">
                    <label className="col-form-label col-4 form-label">Bedrijfsnaam</label>
                    <div className="col-8">
                      <input
                        type="text"
                        name="companyName"
                        className="form-control"
                        placeholder="Bedrijfsnaam"
                        autoComplete="companyname"
                        defaultValue={customer.companyName}
                        onChange={onChange}
                        ref={register({ required: false })}
                        style={{ border: errors.companyName ? '2px solid #D0021B' : '' }}
                      />
                      {errors.companyName && <span className='error'>Voer je bedrijfsnaam in</span>}
                    </div>
                  </div>

                  <div className="form-group required row">
                    <label className="col-form-label col-4 form-label">Wachtwoord</label>
                    <div className="col-8">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Wachtwoord"
                        minLength="6"
                        autoComplete="current-password"
                        defaultValue={customer.password}
                        onChange={onChange}
                        ref={register({ required: true })}
                        style={{ border: errors.password ? '2px solid #D0021B' : '' }}
                      />
                      {errors.password && <span className='error'>Voer je wachtwoord in</span>}
                    </div>
                  </div>
                  <div className="box-footer-nomargin centered">
                      <button id="save" type="submit" className="btn btn-primary" >Schrijf mij in!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="no-sidebar">
        <Footer />
      </div>
    </div>
  )
}
export default Subscribe
