/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getSettings, createOrUpdateSettings } from '../../redux/_actions/settingsAction';
//import UserHelper from '/imports/api/Helpers/UserHelper';
import { setAlert } from '../../redux/_actions/alertAction';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import { useForm } from 'react-hook-form';


const Settings = ({ props }) => {

  const { register, handleSubmit, reset, errors } = useForm();
  let dbSettings = useSelector(state => state.settings.settings);
  const dispatch = useDispatch();

  const [settings, setSettings] = useState({
    _id: '',
    companyName: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    country: '',
    phoneNumber: '',
    companyEmail: '',
    taxNumber: '',
    chamberOfCommerceNumber: '',
    bankName: '',
    bankIBAN: '',
    registerNumber: '',
    agbCode: '',
    website: '',
    taxPercentage: '',
    companyLogo: '',
    currency: ''
  });

  const onChange = (e) =>
    setSettings({ ...settings, [e.target.name]: e.target.value }
  );

  const onSubmit = (data) => {

    if (data.companyName === '' ||
        data.street === '' ||
        data.houseNumber === '' ||
        data.postalCode === '' ||
        data.city === '' ||
        data.country === '' ||
        data.phoneNumber === '' ||
        data.companyEmail === '' ||
        data.taxNumber === '' ||
        data.chamberOfCommerceNumber === '' ||
        data.bankName === '' ||
        data.bankIBAN === '' ||
        data.taxPercentage === ''){
      dispatch(setAlert('Bedrijfsnaam, straat, huisnummer, postcode, plaats, land, telefoonnummer, bedrijdsemail, btwnummer, kvk nummer, bank, IBAN en btw percentage zijn verplicht, voer deze allemaal in.', 'danger'));
    }else{

      if (data && (data._id === undefined || data._id === null)){
        data._id = dbSettings._id;
      }
      dispatch(createOrUpdateSettings(data));
   }
   
 }
   

  useEffect(() => {
   if (window.location.pathname !== '/invoice/create') {
     dispatch(getSettings()).then((set) => {
       if (set) {
         reset(set);
       }
     })
   }
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);



  return (
    <div>
      <SideBar {...props} />
      <div className="body-content content-wrapper">
        <h2 className="icon-settings short" title="Instellingen"> </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Bedrijfsgegevens</h3>
                </div>
                  <div className="box-body">
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Bedrijfsnaam</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="companyName"
                          className="form-control"
                          placeholder="Bedrijfsnaam"
                          defaultValue={dbSettings ? dbSettings.companyName : ''}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.companyName ? '2px solid #D0021B' : '' }}
                        />
                        {errors.companyName && <span className='error'>Voer een bedrijfsnaam in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Straat</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="street"
                          className="form-control"
                          placeholder="Straat"
                          defaultValue={dbSettings ? dbSettings.street : ''}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.street ? '2px solid #D0021B' : '' }}
                        />
                        {errors.street && <span className='error'>Voer een straatnaam in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Huisnummer</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="houseNumber"
                          className="form-control"
                          placeholder="Huisnummer"
                          defaultValue={dbSettings ? dbSettings.houseNumber : ''}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.houseNumber ? '2px solid #D0021B' : '' }}
                        />
                        {errors.houseNumber && <span className='error'>Voer een huisnummer in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Postcode</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="postalCode"
                          className="form-control"
                          placeholder="Postcode"
                          defaultValue={dbSettings ? dbSettings.postalCode : ''}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.postalCode ? '2px solid #D0021B' : '' }}
                        />
                        {errors.postalCode && <span className='error'>Voer een postcode in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Plaats</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          placeholder="Plaats"
                          defaultValue={dbSettings ? dbSettings.city : ''}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.city ? '2px solid #D0021B' : '' }}
                        />
                        {errors.city && <span className='error'>Voer een plaatsnaam in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Land</label>
                      <div className="col-8">
                        <select name="country"
                          ref={register({ required: true })}
                          placeholder="Selecteer een Land"
                          defaultValue={dbSettings ? dbSettings.country : ''}
                          onChange={onChange} 
                          style={{ border: errors.country ? '2px solid #D0021B' : '' }}
                          >
                          <option value="Nederland">Nederland</option>
                          <option value="België">België</option>
                          <option value="Duitsland">Duitsland</option>
                          <option value="Spanje">Spanje</option>
                          <option value="Engeland">Engeland</option>
                        </select>
                        {errors.country && <span className='error'>Kies een land</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Telefoonnummer</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="phoneNumber"
                          className="form-control"
                          placeholder="Telefoonnummer"
                          defaultValue={dbSettings ? dbSettings.phoneNumber : ''}
                          onChange={onChange}
                          ref={register({ required: true })}
                          style={{ border: errors.phoneNumber ? '2px solid #D0021B' : '' }}
                        />
                        {errors.phoneNumber && <span className='error'>Voer een telefoonnummer in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Bedrijfsemail</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="companyEmail"
                          className="form-control"
                          placeholder="Bedrijfsemail"
                          defaultValue={dbSettings ? dbSettings.companyEmail : ''}
                          onChange={onChange}
                          ref={register({
                            required: true,
                            /*eslint no-useless-escape: */
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                          style={{ border: errors.companyEmail ? '2px solid #D0021B' : '' }}
                        />
                        {errors.companyEmail && <span className='error'>Voer een geldig bedrijfsemailadres in</span>}
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">Website</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="website"
                          className="form-control"
                          placeholder="Website"
                          defaultValue={dbSettings ? dbSettings.website : ''}
                          onChange={onChange}
                          ref={register(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary" >Opslaan</button>
                  </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Administratieve gegevens</h3>
                </div>
                  <div className="box-body">
                  <div className="form-group row">
                      <label className="col-form-label col-4 form-label">Valuta</label>
                      <div className="col-8">
                        <select name="currency"
                          ref={register({ required: false })}
                          placeholder="Selecteer de Valuta"
                          defaultValue={dbSettings ? dbSettings.currency : ''}
                          onChange={onChange} >
                          <option value="€">€</option>
                          <option value="$">$</option>
                          <option value="£">£</option>
                          <option value="¥">¥</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-4 form-label">BTW nummer</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="taxNumber"
                          className="form-control"
                          placeholder="BTW nummer"
                          defaultValue={dbSettings ? dbSettings.taxNumber : ''}
                          onChange={onChange}
                          ref={register(false)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-4 form-label">KvK nummer</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="chamberOfCommerceNumber"
                          className="form-control"
                          placeholder="KvK nummer"
                          defaultValue={dbSettings ? dbSettings.chamberOfCommerceNumber : ''}
                          onChange={onChange}
                          ref={register(false)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-4 form-label">Bank</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="bankName"
                          className="form-control"
                          placeholder="Bank"
                          defaultValue={dbSettings ? dbSettings.bankName : ''}
                          onChange={onChange}
                          ref={register(false)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-4 form-label">IBAN</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="bankIBAN"
                          className="form-control"
                          placeholder="IBAN"
                          defaultValue={dbSettings ? dbSettings.bankIBAN : ''}
                          onChange={onChange}
                          ref={register(false)}
                        />
                      </div>
                    </div>
                    <div className="form-group required row">
                      <label className="col-form-label col-4 form-label">BTW percentage</label>
                      <div className="col-8">
                        <select name="taxPercentage"
                          ref={register({ required: true })}
                          placeholder="Selecteer BTW percentage"
                          defaultValue={dbSettings ? dbSettings.taxPercentage : ''}
                          onChange={onChange} 
                          style={{ border: errors.taxPercentage ? '2px solid #D0021B' : '' }}
                          >
                          <option value="21%">21%</option>
                          <option value="9%">9%</option>
                          <option value="6%">6%</option>
                          <option value="0%">0%</option>
                        </select>
                        {errors.taxPercentage && <span className='error'>Kies een btw percentage</span>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-4 form-label">Bedrijfslogo</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="companyLogo"
                          className="form-control"
                          placeholder="Bedrijfslogo"
                          defaultValue={dbSettings ? dbSettings.companyLogo : ''}
                          onChange={onChange}
                          ref={register(false)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-4 form-label">Register nummer</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="registerNumber"
                          className="form-control"
                          placeholder="Register nummer"
                          defaultValue={dbSettings ? dbSettings.registerNumber : ''}
                          onChange={onChange}
                          ref={register(false)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-4 form-label">AGB Code</label>
                      <div className="col-8">
                        <input
                          type="text"
                          name="agbCode"
                          className="form-control"
                          placeholder="AGB Code"
                          defaultValue={dbSettings ? dbSettings.agbCode : ''}
                          onChange={onChange}
                          ref={register(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-primary" >Opslaan</button>
                  </div>
              </div>
            </div>
          </div>
        </form>
        <Footer />
      </div>
    </div>
  );
}
export default Settings