import { INVOICES_LOADED, 
         INVOICES_LOADED_FAIL, 
         INVOICE_LOADED, 
         INVOICE_LOADED_FAIL, 
         INVOICE_UPDATED_OR_CREATED_SUCCESS, 
         INVOICE_UPDATED_OR_CREATED_FAIL,
         INVOICES_LIST_LOADED, 
         INVOICES_LIST_LOADED_FAIL,
         INVOICE_DELETED,
         INVOICE_DELETED_FAIL  } from '../invoiceTypes'

import axios from 'axios';
import { setAlert } from './alertAction'


export const getInvoices = (query) => {
  return async dispatch => {
    try {
          const config = {header: {'Content-Type': 'application/json'}}  
          const res = await axios.get(`/api/invoices${query}`, config);
          dispatch({type: INVOICES_LOADED, payload: res.data.data});
          return res.data.data;
        } catch (err) {
          console.log(err);
          dispatch({type: INVOICES_LOADED_FAIL, payload: err.response.data.message});
          dispatch(setAlert('Er is iets misgegaan bij het laden van de facturen, probeer het nogmaals.', 'danger'));
        }     
    }
  }

export const getInvoice = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/invoice/${id}`, config);
            dispatch({type: INVOICE_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: INVOICE_LOADED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het laden van de factuur, probeer het nogmaals.', 'danger'));
          }     
      }
  }

export const createOrUpdateInvoice = (contact) => {
  return async dispatch => {
    try {
          const config = {header: {'Content-Type': 'application/json'}}  
          const res = await axios.post(`/api/invoice`, contact, config);
          dispatch({type: INVOICE_UPDATED_OR_CREATED_SUCCESS, payload: res.data.data});
          dispatch(setAlert('De factuur is succesvol opgeslagen.', 'success'));
          return res.data.data;
        } catch (err) {
          console.log(err);
          dispatch({type: INVOICE_UPDATED_OR_CREATED_FAIL, payload: err.response.data.message});
          dispatch(setAlert('Er is iets misgegaan bij het opslaan van de factuur.', 'danger'));
        }     
    }
}

export const getInvoicesList = () => {
  return async dispatch => {
    try {
          const config = {header: {'Content-Type': 'application/json'}}  
          const res = await axios.get(`/api/invoices`, config);
          dispatch({type: INVOICES_LIST_LOADED, payload: res.data.data});
          return res.data.data;
        } catch (err) {
          console.log(err);
          dispatch({type: INVOICES_LIST_LOADED_FAIL, payload: err.response.data.message});
          dispatch(setAlert('Er is iets misgegaan bij het laden van de facturen voor de dropdown, probeer het nogmaals.', 'danger'));
        }     
    }
  }


export const deleteInvoice= (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.delete(`/api/invoice/${id}`, config);
            dispatch({type: INVOICE_DELETED, payload: res.data.data});
          } catch (err) {
            console.log(err);
            dispatch({type: INVOICE_DELETED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het verwijderen van de factuur, probeer het nogmaals.', 'danger'));
          }     
      }
  }

