import { EMAIL_LOADED, 
         EMAIL_LOADED_FAIL, 
         EMAILS_LOADED, 
         EMAILS_LOADED_FAIL, 
         EMAIL_UPDATED_SUCCESS, 
         EMAIL_UPDATED_FAIL, 
         EMAIL_DELETED,
         EMAIL_DELETED_FAIL,
         EMAIL_SEND_SUCCESS,
        EMAIL_SEND_FAIL
        } from '../emailTypes'
import axios from 'axios';
import { setAlert } from './alertAction'


export const getEmails = (query) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/emails${query}`, config);
            dispatch({type: EMAILS_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: EMAILS_LOADED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het laden van de email, probeer het nogmaals.', 'danger'));
          }     
      }
}


export const getEmail = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/email/${id}`, config);
            dispatch({type: EMAIL_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: EMAIL_LOADED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het laden van de email, probeer het nogmaals.', 'danger'));
          }     
      }
}

export const createOrUpdateEmail = (email) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.post(`/api/email`, email, config);
            dispatch({type: EMAIL_UPDATED_SUCCESS, payload: res.data.data});
            dispatch(setAlert('De email is succesvol opgeslagen.', 'success'));
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: EMAIL_UPDATED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het opslaan van de email.', 'danger'));
          }     
      }
  }

export const deleteEmail = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.delete(`/api/email/${id}`, config);
            dispatch({type: EMAIL_DELETED, payload: res.data.data});
          } catch (err) {
            console.log(err);
            dispatch({type: EMAIL_DELETED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het verwijderen van de email, probeer het nogmaals.', 'danger'));
          }     
      }
  }

  export const sendEmail = (email) => {
    return async dispatch => {
        try {
              const config = {header: {'Content-Type': 'application/json'}}  
              const res = await axios.post(`/api/email/send`, email, config);
              dispatch({type: EMAIL_SEND_SUCCESS, payload: res.data.data});
              dispatch(setAlert('De email is succesvol verzonden.', 'success'));
              return res.data.data;
            } catch (err) {
              console.log(err);
              dispatch({type: EMAIL_SEND_FAIL, payload: err.response.data.message});
              dispatch(setAlert('Er is iets misgegaan bij het verzenden van de email.', 'danger'));
            }     
        }
    }
