import { NOTE_LOADED, 
         NOTE_LOADED_FAIL, 
         NOTES_LOADED, 
         NOTES_LOADED_FAIL, 
         NOTE_UPDATED_SUCCESS, 
         NOTE_UPDATED_FAIL, 
         NOTE_DELETED,
         NOTE_DELETED_FAIL
        } from '../noteTypes'
import axios from 'axios';
import { setAlert } from './alertAction'


export const getNotes = (query) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/notes${query}`, config);
            dispatch({type: NOTES_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: NOTES_LOADED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het laden van de notitie, probeer het nogmaals.', 'danger'));
          }     
      }
}

export const getNote = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/note/${id}`, config);
            dispatch({type: NOTE_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: NOTE_LOADED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het laden van de notitie, probeer het nogmaals.', 'danger'));
          }     
      }
}

export const createOrUpdateNote = (note) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.post(`/api/note`, note, config);
            dispatch({type: NOTE_UPDATED_SUCCESS, payload: res.data.data});
            dispatch(setAlert('De notitie is succesvol opgeslagen.', 'success'));
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: NOTE_UPDATED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het opslaan van de notitie.', 'danger'));
          }     
      }
  }

export const deleteNote = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.delete(`/api/note/${id}`, config);
            dispatch({type: NOTE_DELETED, payload: res.data.data});
          } catch (err) {
            console.log(err);
            dispatch({type: NOTE_DELETED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het verwijderen van de notitie, probeer het nogmaals.', 'danger'));
          }     
      }
  }

