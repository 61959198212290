import {  INVOICES_LOADED, 
          INVOICES_LOADED_FAIL, 
          INVOICE_LOADED, 
          INVOICE_LOADED_FAIL,
          INVOICE_UPDATED_OR_CREATED_SUCCESS, 
          INVOICE_UPDATED_OR_CREATED_FAIL,
          INVOICES_LIST_LOADED, 
          INVOICES_LIST_LOADED_FAIL,
          INVOICE_DELETED,
          INVOICE_DELETED_FAIL   } from '../invoiceTypes'

const initState = {
  invoice: null,
  error: null,
  loading: true,
  offset: 0,
  totalDocs: 0,
  totalPages: 0,
  limit: 0
}

const invoiceReducer = (state = initState, action) => {
  switch(action.type){
      case INVOICE_LOADED:
        return {
          ...state,
          loading: false,
          invoice: action.payload
         }
      case INVOICES_LOADED:
        const { totalDocs, limit, offset, totalPages } = action.payload;
        return {
          ...state,
          loading: false,
          invoices: action.payload.docs,
          limit: limit,
          offset:  offset,
          totalDocs: totalDocs ,
          totalPages: totalPages 
        }
      case  INVOICES_LIST_LOADED:
        return {
          ...state,
          loading: false,
          invoicesList: action.payload.docs
        } 
      case INVOICES_LIST_LOADED_FAIL:
      return state;
      case INVOICE_LOADED_FAIL:
        return state;
      case INVOICES_LOADED_FAIL:
        return state;
      case INVOICE_UPDATED_OR_CREATED_FAIL:
        return state;
      case INVOICE_DELETED:
        const newInvoicelist = state.invoices.filter((item) => item._id !== action.payload._id);
        return {
          ...state,
          loading: false,
          invoices: newInvoicelist,
        //  invoice: action.payload
         }
      case INVOICE_DELETED_FAIL:
      case INVOICE_UPDATED_OR_CREATED_SUCCESS:
        return state;
      default:
        return state;
  }     
}

export default invoiceReducer;