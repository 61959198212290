import React from 'react';
import { Link } from 'react-router-dom';

const NoInvoicesFound = ({contactName}) => (
    <div>
      <h2 className="icon-calculator short" title="Facturen"> </h2><Link className="icon-add white pull-right top-margin-28" to="/invoice/create" title="Nieuwe factuur">  Nieuwe factuur</Link>
      <div className="row">
        <div className="col-12">
          <div className="box box box-primary">
            <div className="box-header">
              <h3 className="box-title">Facturen {contactName ? 'voor ' + contactName : ''}</h3>
             </div>

             <div className="box-body table-responsive no-padding">
                <div>Geen facturen gevonden...</div>
            </div>
           </div>
         </div>
      </div>
    </div>
);

export default NoInvoicesFound;
