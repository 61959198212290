import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PublicNavigation from '../PublicNavigation/PublicNavigation';
import AuthenticatedNavigation from '../AuthenticatedNavigation/AuthenticatedNavigation';
import { useSelector } from 'react-redux'


const Navigation = props => {
  
  const auth = useSelector(state => state.auth);

  return(
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Navbar.Brand>
        <Link to="/">paperwork</Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        {!auth.isAuthenticated ? <PublicNavigation /> : <AuthenticatedNavigation {...props} />}
 
      </Navbar.Collapse>
    </Navbar>
  )
};

Navigation.defaultProps = {
  name: '',
};

export default Navigation;
