import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getInvoices, deleteInvoice } from '../../redux/_actions/invoiceAction';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import NoInvoicesFound from '../NoInvoicesFound/NoInvoicesFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';



const InvoicesTable = ({ props, contactId }) => {
  const invoices = useSelector(state => state.invoice.invoices);
  const pagination = useSelector(state => state.invoice);
  const dispatch = useDispatch();
  let createNewUrl = '/invoice/create';
  if (contactId){
    createNewUrl = `/invoice/create/${contactId}`;
  }

  const [currentPage, setCurrentPage] = useState(1);

  const removeInvoice = (invoiceId) =>{
    confirmAlert({
      title: 'Factuur verwijderen',
      message: 'Weet je zeker dat je de factuur wilt verwijderen?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => dispatch(deleteInvoice(invoiceId))
        },
        {
          label: 'Nee',
        }
      ]
    });
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getInvoices(`?offset=${pageNumber * 10 - 10}`));
  }

  async function fetchData(query) {
    dispatch(getInvoices(query));
  }

  useEffect(() => {
    let offset = 0;
    if (pagination && pagination.offset) {
      offset = pagination.offset;
    }
    let query = '';
    if(contactId){
      query = `&contactId=${contactId}`;
    }
    fetchData(`?offset=${offset}${query}`);
    // eslint-disable-next-line
  }, []);
  
  const renderInvoices = () => {
     return invoices.map((invoice) => (
       <tr key={invoice._id}>
         <td className="responsive-hidden">{invoice.invoiceNumber}</td>
         <td>{new Date(invoice.invoiceDate).toISOString().substr(0,10)}</td>
         <td>{invoice && invoice.contactName ? invoice.contactName :  invoice.contactId || '' }</td>
         <td>{invoice.state}</td>
         <td>
           <Link className="icon-compose" to={"/invoice/edit/" + invoice._id} title="Aanpassen"> </Link>
           <Link className="icon-calculator" to={"/invoice/details/" + invoice._id} title="Details"> </Link>
           <span className="icon-trash" onClick={() => removeInvoice(invoice._id)} title="Verwijderen"> </span>
         </td>
       </tr>
   ));
 }

 return invoices == null || invoices.length < 1 ? (
      <NoInvoicesFound  />
      ) : (
        <div>
          <h2 className="icon-calculator short" title="Facturen"> </h2><Link className="icon-add white pull-right top-margin-28" to={createNewUrl} title="Nieuwe factuur">  Nieuwe factuur</Link>
          <div className="row">
            <div className="col-12">
              <div className="box box box-primary">
                <div className="box-header">
                  <h3 className="box-title">Facturen {props && props.contactName  ? 'voor ' + props.contactName : ''}</h3>

                  <div className="box-tools">
                    <div className="input-group input-group-sm" style={{maxWidth: '350px'}}>
                      <input type="text" name="table_search" className="form-control pull-right" placeholder="Zoeken" />

                      <div className="input-group-btn">
                        <button type="submit" className="btn btn-default search"><FontAwesomeIcon icon={faSearch} /></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-body table-responsive no-padding">
                    <table className="table onelinertable table-hover">
                      <tbody>
                        <tr><th className="responsive-hidden">Nummer</th><th>Datum</th><th>Contact</th><th>Status</th><th className="icon-header"></th></tr>
                          {renderInvoices()}
                        </tbody>
                    </table>
                    <div className="text-center">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={10}
                      totalItemsCount={pagination.totalDocs}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      )
}
export default InvoicesTable;