
export const INVOICE_LOADED = 'INVOICE_LOADED';
export const INVOICE_LOADED_FAIL = 'INVOICE_LOADED_FAIL';

export const INVOICES_LOADED = 'INVOICES_LOADED';
export const INVOICES_LOADED_FAIL = 'INVOICES_LOADED_FAIL';

export const INVOICES_LIST_LOADED = 'INVOICES_LIST_LOADED';
export const INVOICES_LIST_LOADED_FAIL = 'INVOICES_LIST_LOADED_FAIL';

export const INVOICE_UPDATED_OR_CREATED_SUCCESS = 'INVOICE_UPDATED_OR_CREATED_SUCCESS';
export const INVOICE_UPDATED_OR_CREATED_FAIL = 'INVOICE_UPDATED_OR_CREATED_FAIL';
export const INVOICE_DELETED = 'INVOICE_DELETED';
export const INVOICE_DELETED_FAIL = 'INVOICE_DELETED_FAIL';




 