import { combineReducers } from 'redux'
import authReducer from './authReducer';
import alertReducer from './alertReducer';
import contactReducer from './contactReducer';
import invoiceReducer from './invoiceReducer';
import settingsReducer from './settingsReducer';
import expenseReducer from './expenseReducer';
import noteReducer from './noteReducer';
import emailReducer from './emailReducer';

export default combineReducers({
  auth: authReducer,
  alert: alertReducer,
  contact: contactReducer,
  invoice: invoiceReducer,
  settings: settingsReducer,
  expense: expenseReducer,
  note: noteReducer,
  email: emailReducer
});