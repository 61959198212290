import {  SETTINGS_LOADED, 
          SETTINGS_LOADED_FAIL, 
          SETTINGS_UPDATED_OR_CREATED_SUCCESS, 
          SETTINGS_UPDATED_OR_CREATED_FAIL } from '../settingsTypes'
import axios from 'axios';
import { setAlert } from './alertAction'


export const getSettings = () => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/settings`, config);
            dispatch({type: SETTINGS_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: SETTINGS_LOADED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het laden van de instellingen, probeer het nogmaals.', 'danger'));
          }     
      }
}


export const createOrUpdateSettings = (settings) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.post(`/api/settings`, settings, config);
            dispatch({type: SETTINGS_UPDATED_OR_CREATED_SUCCESS, payload: res.data.data});
            dispatch(setAlert('De instellingen zijn succesvol opgeslagen.', 'success'));
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: SETTINGS_UPDATED_OR_CREATED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het opslaan van de settings.', 'danger'));
          }     
      }
  }

