import {  NOTE_LOADED, 
          NOTE_LOADED_FAIL, 
          NOTES_LOADED, 
          NOTES_LOADED_FAIL, 
          NOTE_UPDATED_SUCCESS, 
          NOTE_UPDATED_FAIL, 
          NOTE_DELETED, 
          NOTE_DELETED_FAIL } from '../noteTypes'

const initState = {
  note: null,
  error: null,
  loading: true,
  offset: 0,
  totalDocs: 0,
  totalPages: 0,
  limit: 0
}

const noteReducer = (state = initState, action) => {
  switch(action.type){
      case NOTE_LOADED:
        return {
          ...state,
          loading: false,
          note: action.payload
         }
      case NOTES_LOADED:
      const { totalDocs, limit, offset, totalPages } = action.payload;
      return {
          ...state,
          loading: false,
          notes: action.payload.docs,
          limit: limit,
          offset:  offset,
          totalDocs: totalDocs ,
          totalPages: totalPages 
        }
      case NOTE_LOADED_FAIL:
        return state;
      case NOTE_UPDATED_SUCCESS:
        return state;
      case NOTE_UPDATED_FAIL:
        return state;
      case NOTES_LOADED_FAIL:
        return state;
      case NOTE_DELETED:
        const newNotelist = state.notes.filter((item) => item._id !== action.payload._id);
        return {
          ...state,
          loading: false,
          notes: newNotelist,
        //  note: action.payload
         }
      case NOTE_DELETED_FAIL:
        return state;
      default:
        return state;
  }     
}

export default noteReducer;