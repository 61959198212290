import React from 'react';
import './css/01-bootstrap.css';
import './css/02-bootstrap-theme.css';
import './css/03-eightyshades.css';
import './css/04-site.css';
import './css/05-skins.css';

import setAuthToken from './utils/setAuthToken';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute'
import Home from './pages/Home/Home'
import Login from './components/authentication/Login'
import Register from './components/authentication/Register'
import Alerts from './components/partials/Alerts'
import Navigation from './components/Navigation/Navigation';
import About from './pages/About/About';
import ContactPage from './pages/ContactPage/ContactPage';
import Profile from './pages/Profile/Profile';
import Contacts from './pages/Contacts/Contacts';
import Contact from './pages/Contact/Contact';
import Invoices from './pages/Invoices/Invoices';
import Invoice from './pages/Invoice/Invoice';
import InvoiceDetails from './pages/Invoice/InvoiceDetails';
import Settings from './pages/Settings/Settings';
import Expenses from './pages/Expenses/Expenses';
import Expense from './pages/Expense/Expense';
import Notes from './pages/Notes/Notes';
import Note from './pages/Note/Note';
import Emails from './pages/Emails/Emails';
import Email from './pages/Email/Email';
import Subscribe from './pages/Subscribe/Subscribe';
import Success from './pages/Payments/Success';
import NotFound from './pages/NotFound/NotFound';

 //TODO: make sure to change /payments/success route to privateroute

if(localStorage.token){
  setAuthToken(localStorage.token);
}

function App() {
  return (
    <>
   <Router>
     <Navigation />
     <div className="container">
     <Alerts />
      <Switch>
        <Route exact path='/' component={Home}/> 
        <Route exact path='/register' component={Register}/>
        <Route exact path='/login' component={Login}/>
        <Route exact path='/about' component={About}/>
        <Route exact path='/contactus' component={ContactPage}/>
        <Route exact path='/subscribe' component={Subscribe}/>
        <PrivateRoute  path='/payments/success' component={Success}/>
        <PrivateRoute  path='/profile' component={Profile}/>
        <PrivateRoute  path='/contacts' component={Contacts}/>
        <PrivateRoute  path='/contact/create' component={Contact}/>
        <PrivateRoute  path='/contact/edit/:id' component={Contact}/>
        <PrivateRoute  path='/invoices' component={Invoices}/>
        <PrivateRoute  path='/invoice/create/:contactId?' component={Invoice}/>
        <PrivateRoute  path='/invoice/edit/:id' component={Invoice}/>
        <PrivateRoute  path='/invoice/details/:id' component={InvoiceDetails}/>
        <PrivateRoute  path='/expenses' component={Expenses}/>
        <PrivateRoute  path='/expense/create' component={Expense}/>
        <PrivateRoute  path='/expense/edit/:id' component={Expense}/> 
        <PrivateRoute  path='/notes' component={Notes}/>
        <PrivateRoute  path='/note/create' component={Note}/>
        <PrivateRoute  path='/note/edit/:id' component={Note}/> 
        <PrivateRoute  path='/emails' component={Emails}/>
        <PrivateRoute  path='/email/create' component={Email}/>
        <PrivateRoute  path='/email/edit/:id' component={Email}/> 
        <PrivateRoute  path='/invoice/send/:invoiceId' component={Email}/>
        <PrivateRoute  path='/settings' component={Settings}/>
        <Route  path='*' component={NotFound}/>
      </Switch>
     </div>     
   </Router>
   </>
  );
}

export default App;
