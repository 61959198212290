import {  CONTACT_LOADED, 
          CONTACT_LOADED_FAIL, 
          CONTACTS_LOADED, 
          CONTACTS_LOADED_FAIL, 
          CONTACT_UPDATED_SUCCESS, 
          CONTACT_UPDATED_FAIL, 
          CONTACT_DELETED, 
          CONTACT_DELETED_FAIL,
          CONTACTS_BY_TYPE_LOADED,
          CONTACTS_BY_TYPE_LOADED_FAIL } from '../contactTypes'

const initState = {
  contact: null,
  error: null,
  loading: true,
  offset: 0,
  totalDocs: 0,
  totalPages: 0,
  limit: 0
}

const contactReducer = (state = initState, action) => {
  switch(action.type){
      case CONTACT_LOADED:
        return {
          ...state,
          loading: false,
          contact: action.payload
         }
      case CONTACTS_LOADED:
      const { totalDocs, limit, offset, totalPages } = action.payload;
      return {
          ...state,
          loading: false,
          contacts: action.payload.docs,
          limit: limit,
          offset:  offset,
          totalDocs: totalDocs ,
          totalPages: totalPages 
        }
      case CONTACTS_BY_TYPE_LOADED:
        return {
          ...state,
          loading: false,
          contactsByType: action.payload
        }
      case CONTACT_LOADED_FAIL:
        return state;
      case CONTACT_UPDATED_SUCCESS:
        return state;
      case CONTACT_UPDATED_FAIL:
        return state;
      case CONTACTS_BY_TYPE_LOADED_FAIL:
        return state;
      case CONTACTS_LOADED_FAIL:
        return state;
      case CONTACT_DELETED:
        const newContactlist = state.contacts.filter((item) => item._id !== action.payload._id);
          return {
            ...state,
            loading: false,
            contacts: newContactlist,
       //     contact: action.payload
           }
      case CONTACT_DELETED_FAIL:
        return state;
      default:
        return state;
  }     
}

export default contactReducer;