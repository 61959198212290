import {  EMAIL_LOADED, 
          EMAIL_LOADED_FAIL, 
          EMAILS_LOADED, 
          EMAILS_LOADED_FAIL, 
          EMAIL_UPDATED_SUCCESS, 
          EMAIL_UPDATED_FAIL, 
          EMAIL_DELETED, 
          EMAIL_DELETED_FAIL,
          EMAIL_SEND_SUCCESS,
          EMAIL_SEND_FAIL  } from '../emailTypes'

const initState = {
  email: null,
  error: null,
  loading: true,
  offset: 0,
  totalDocs: 0,
  totalPages: 0,
  limit: 0
}

const emailReducer = (state = initState, action) => {
  switch(action.type){
      case EMAIL_LOADED:
        return {
          ...state,
          loading: false,
          email: action.payload
         }
      case EMAILS_LOADED:
      const { totalDocs, limit, offset, totalPages } = action.payload;
      return {
          ...state,
          loading: false,
          emails: action.payload.docs,
          limit: limit,
          offset:  offset,
          totalDocs: totalDocs ,
          totalPages: totalPages 
        }
      case EMAIL_LOADED_FAIL:
        return state;
      case EMAIL_UPDATED_SUCCESS:
        return state;
      case EMAIL_UPDATED_FAIL:
        return state;
      case EMAILS_LOADED_FAIL:
        return state;
      case EMAIL_SEND_SUCCESS:
        console.log(action.payload);
        return {
          ...state,
          loading: false,
          email: action.payload
         }
      case EMAIL_SEND_FAIL:
        return state;
      case EMAIL_DELETED:
        const newEmaillist = state.emails.filter((item) => item._id !== action.payload._id);
        return {
          ...state,
          loading: false,
          emails: newEmaillist,
        //  email: action.payload
         }
      case EMAIL_DELETED_FAIL:
        return state;
      default:
        return state;
  }     
}

export default emailReducer;