import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getContacts, deleteContact } from '../../redux/_actions/contactAction';
import SideBar from '../../components/Sidebar/SideBar';
import Footer from '../../components/Footer/Footer';
import NoContactFound from '../../components/NoContactFound/NoContactFound';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from 'react-js-pagination';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
// import { USER_LOADED, AUTH_ERROR } from '../../redux/authTypes';
// import axios from 'axios';
// import setAuthToken from '../../utils/setAuthToken';


const Contacts = ({ props, history }) => {

  const contacts = useSelector(state => state.contact.contacts);
  const pagination = useSelector(state => state.contact);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  //const [lastName, setLastName] = useState('');

  const removeContact = contactId => {

    confirmAlert({
      title: 'Contact verwijderen',
      message: 'Weet je zeker dat je dit contact wilt verwijderen?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => dispatch(deleteContact(contactId)),
        },
        {
          label: 'Nee',
        }
      ]
    });
  }

  // const loadUsers = async () => {
  //   setAuthToken(localStorage.token);
  //   try {
  //     const res = await axios.get('/api/auth/me');
  //     setLastName(res.data.data.lastName);
  //     console.log(lastName);
  //     dispatch({ type: USER_LOADED, payload: res.data });
  //   } catch (err) {
  //     console.log(err);
  //     dispatch({ type: AUTH_ERROR, payload: err.message });
  //   }
  // }


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getContacts(`?offset=${pageNumber * 10 - 10}`));
  }

  async function fetchData(query) {
    dispatch(getContacts(query));
  }

  useEffect(() => {
    // if (localStorage.token) {
    //   loadUsers();

    let offset = 0;
    if (pagination && pagination.offset) {
      offset = pagination.offset;
    }
    fetchData(`?offset=${offset}`);
    // } else {
    //   history.push('/login');
    // }
    // eslint-disable-next-line
  }, []);

  const renderContacts = () => {
    return contacts.map((contact) => (
      <tr key={contact._id}>
        <td>{contact.contactNumber}</td>
        <td className="responsive-hidden">{contact.typeName === 'Particulier' ? contact.lastName + ', ' + contact.firstName : contact.companyName}</td>
        <td>{contact.emailAddress}</td>
        <td className="responsive-hidden">{contact.city}</td>
        <td className="responsive-hidden">{contact.typeName}</td>
        <td>
          <Link className="icon-compose" to={"/contact/edit/" + contact._id} title="Aanpassen"> </Link>
          <Link className="icon-calculator" to={"/invoice/create/" + contact._id} title="Nieuwe factuur"> </Link>
          <span className="icon-trash" onClick={() => removeContact(contact._id)} title="Verwijderen"> </span>
        </td>
      </tr>
    ));
  }

  return contacts == null || contacts.length < 1 ?
    (
      <div>
        <SideBar {...props} />
        <div className="body-content content-wrapper">
          <NoContactFound />
          <Footer />
        </div>
      </div>
    ) : (
      <div>
        <SideBar {...props} />
        <div className="body-content content-wrapper">
          <h2 className="icon-users short" title="Contacten/klanten"> </h2><Link className="icon-add white pull-right top-margin-28" to="/contact/create" title="Nieuwe klant">  Nieuw contact</Link>
          <div className="row">
            <div className="col-12">
              <div className="box box box-primary">
                <div className="box-header">
                  <h3 className="box-title">Contacten</h3>

                  <div className="box-tools">
                    <div className="input-group input-group-sm" style={{ maxWidth: '350px' }}>
                      <input type="search" name="table_search" className="form-control pull-right" placeholder="Zoeken" />

                      <div className="input-group-btn">
                        <button type="submit" className="btn btn-default search"><FontAwesomeIcon icon={faSearch} /></button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box-body table-responsive no-padding">


                  <table className="table onelinertable table-hover">
                    <tbody>
                      <tr><th>Nummer</th><th className="responsive-hidden">Naam</th><th>Email</th><th className="responsive-hidden">Plaats</th><th className="responsive-hidden">Type</th><th className="icon-header"></th></tr>
                      {renderContacts()}
                    </tbody>
                  </table>
                  <div className="text-center">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={10}
                      totalItemsCount={pagination.totalDocs}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>

                </div>

              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
}
export default Contacts;