import {  SETTINGS_LOADED, 
          SETTINGS_LOADED_FAIL, 
          SETTINGS_UPDATED_OR_CREATED_SUCCESS, 
          SETTINGS_UPDATED_OR_CREATED_FAIL } from '../settingsTypes'

const initState = {
  settings: null,
  error: null,
  loading: true
}

const contactReducer = (state = initState, action) => {
  switch(action.type){
      case SETTINGS_LOADED:
        return {
          ...state,
          loading: false,
          settings: action.payload
         }
      
      case SETTINGS_LOADED_FAIL:
        return state;
      case SETTINGS_UPDATED_OR_CREATED_SUCCESS: 
      return state;
      case SETTINGS_UPDATED_OR_CREATED_FAIL:
        return state;
      default:
        return state;
  }     
}

export default contactReducer;