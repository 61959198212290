import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getNotes, deleteNote } from '../../redux/_actions/noteAction';
import NoNotesFound from '../../components/NoNotesFound/NoNotesFound';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Pagination from 'react-js-pagination';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import moment from 'moment';

const NotesTable = ({ props, history }) => {

  const notes = useSelector(state => state.note.notes);
  const pagination = useSelector(state => state.note);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);


  const removeNote = (noteId) => {
    confirmAlert({
      title: 'Notitie verwijderen',
      message: 'Weet je zeker dat je de notitie wilt verwijderen?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => dispatch(deleteNote(noteId))
        },
        {
          label: 'Nee',
        }
      ]
    });
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getNotes(`?offset=${pageNumber * 10 - 10}`));
  }

  async function fetchData(query) {
    dispatch(getNotes(query));
  }

  useEffect(() => {
    let offset = 0;
    if (pagination && pagination.offset) {
      offset = pagination.offset;
    }
    fetchData(`?offset=${offset}`);
    // eslint-disable-next-line
  }, []);

  const renderNotes = () => {
     return notes.map((note) => (
       <tr key={note._id}>
          <td className="responsive-hidden">{note.noteNumber}</td>
         <td>{note.noteDate ? moment(new Date(note.noteDate)).format('yyyy-MM-DD') : ''}</td>
         <td className="responsive-hidden">{note.description}</td>
         <td>{note && note.contactName ? note.contactName :  note.contactId || '' }</td>
         <td>
           <Link className="icon-compose" to={"/note/edit/" + note._id} title="Aanpassen"> </Link>
           <span className="icon-trash" onClick={() => removeNote(note._id)} title="Verwijderen"> </span>
         </td>
       </tr>
   ));
 }

 return notes == null || notes.length < 1 ?
    (
       <NoNotesFound contactName={props && props.contactName ?  props.contactName : ''} />
    ) : (
      <div>
        <h2 className="icon-list short" title="Notities"> </h2><Link className="icon-add white pull-right top-margin-28" to="/note/create" title="Nieuwe notitie">  Nieuwe notitie</Link>
        <div className="row">
          <div className="col-12">
            <div className="box box box-primary">
              <div className="box-header">
                <h3 className="box-title">Notities {props && props.contactName ? 'voor ' + props.contactName : ''}</h3>

                <div className="box-tools">
                  <div className="input-group input-group-sm" style={{maxWidth: '350px'}}>
                    <input type="text" name="table_search" className="form-control pull-right" placeholder="Zoeken" />

                    <div className="input-group-btn">
                      <button type="submit" className="btn btn-default search"><FontAwesomeIcon icon={faSearch} /></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-body table-responsive no-padding">
                  <table className="table onelinertable table-hover">
                    <tbody>
                      <tr><th>Nummer</th><th>Datum</th><th className="responsive-hidden">Omschrijving</th><th>Contact</th><th className="icon-header"></th></tr>
                        {renderNotes()}
                      </tbody>
                  </table>
                  <div className="text-center">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={10}
                      totalItemsCount={pagination.totalDocs}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>

                </div>
              </div>
            </div>
        </div>
      </div>
    );
}
export default NotesTable