import { EXPENSES_LOADED, 
         EXPENSES_LOADED_FAIL, 
         EXPENSE_LOADED, 
         EXPENSE_LOADED_FAIL, 
         EXPENSE_UPDATED_OR_CREATED_SUCCESS, 
         EXPENSE_UPDATED_OR_CREATED_FAIL,
         EXPENSE_DELETED,
         EXPENSE_DELETED_FAIL } from '../expenseTypes'

import axios from 'axios';
import { setAlert } from './alertAction'


export const getExpenses = (query) => {
  return async dispatch => {
    try {
          const config = {header: {'Content-Type': 'application/json'}}  
          const res = await axios.get(`/api/expenses${query}`, config);
          dispatch({type: EXPENSES_LOADED, payload: res.data.data});
          return res.data.data;
        } catch (err) {
          console.log(err);
          dispatch({type: EXPENSES_LOADED_FAIL, payload: err.response.data.message});
          dispatch(setAlert('Er is iets misgegaan bij het laden van de kosten, probeer het nogmaals.', 'danger'));
        }     
    }
  }

export const getExpense = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.get(`/api/expense/${id}`, config);
            dispatch({type: EXPENSE_LOADED, payload: res.data.data});
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: EXPENSE_LOADED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het laden van de kosten, probeer het nogmaals.', 'danger'));
          }     
      }
  }

export const createOrUpdateExpense = (contact) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.post(`/api/expense`, contact, config);
            dispatch({type: EXPENSE_UPDATED_OR_CREATED_SUCCESS, payload: res.data.data});
            dispatch(setAlert('De kosten zijn succesvol opgeslagen.', 'success'));
            return res.data.data;
          } catch (err) {
            console.log(err);
            dispatch({type: EXPENSE_UPDATED_OR_CREATED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het opslaan van de kosten.', 'danger'));
          }     
      }
  }

export const deleteExpense = (id) => {
  return async dispatch => {
      try {
            const config = {header: {'Content-Type': 'application/json'}}  
            const res = await axios.delete(`/api/expense/${id}`, config);
            dispatch({type: EXPENSE_DELETED, payload: res.data.data});
          } catch (err) {
            console.log(err);
            dispatch({type: EXPENSE_DELETED_FAIL, payload: err.response.data.message});
            dispatch(setAlert('Er is iets misgegaan bij het verwijderen van de kosten, probeer het nogmaals.', 'danger'));
          }     
      }
  }
